export const championsLeagueJson = {
    "pot1": {
        "RealMadrid": {
            "pot1": {
                "home": "Borussia Dortmund",
                "away": "Liverpool"
            },
            "pot2": {
                "home": "AC Milan",
                "away": "Atalanta"
            },
            "pot3": {
                "home": "FC Red Bull Salzburg",
                "away": "Lille"
            },
            "pot4": {
                "home": "VfB Stuttgart",
                "away": "Brest"
            }
        },
        "ManchesterCity": {
            "pot1": {
                "home": "Inter Milan",
                "away": "Paris Saint Germain"
            },
            "pot2": {
                "home": "Club Brugge",
                "away": "Juventus"
            },
            "pot3": {
                "home": "Feyenoord",
                "away": "Sporting CP"
            },
            "pot4": {
                "home": "Sparta Praha",
                "away": "Midtjylland"
            }
        },
        "FCBayernMünchen": {
            "pot1": {
                "home": "Paris Saint Germain",
                "away": "Barcelona FC"
            },
            "pot2": {
                "home": "Benfica",
                "away": "Shakhtar Donetsk"
            },
            "pot3": {
                "home": "Dinamo Zagreb",
                "away": "Feyenoord"
            },
            "pot4": {
                "home": "Midtjylland",
                "away": "Aston Villa"
            }
        },
        "ParisSaintGermain": {
            "pot1": {
                "home": "Manchester City",
                "away": "FC Bayern München"
            },
            "pot2": {
                "home": "Atlético Madrid",
                "away": "Arsenal"
            },
            "pot3": {
                "home": "PSV",
                "away": "FC Red Bull Salzburg"
            },
            "pot4": {
                "home": "Girona",
                "away": "VfB Stuttgart"
            }
        },
        "Liverpool": {
            "pot1": {
                "home": "Real Madrid",
                "away": "RB Leipzig"
            },
            "pot2": {
                "home": "Bayer 04 Leverkusen",
                "away": "AC Milan"
            },
            "pot3": {
                "home": "Lille",
                "away": "PSV"
            },
            "pot4": {
                "home": "Bologna",
                "away": "Girona"
            }
        },
        "InterMilan": {
            "pot1": {
                "home": "RB Leipzig",
                "away": "Manchester City"
            },
            "pot2": {
                "home": "Arsenal",
                "away": "Bayer 04 Leverkusen"
            },
            "pot3": {
                "home": "FK Bodø/Glimt",
                "away": "Young Boys"
            },
            "pot4": {
                "home": "AS Monaco",
                "away": "Sparta Praha"
            }
        },
        "BorussiaDortmund": {
            "pot1": {
                "home": "Barcelona FC",
                "away": "Real Madrid"
            },
            "pot2": {
                "home": "Shakhtar Donetsk",
                "away": "Club Brugge"
            },
            "pot3": {
                "home": "Celtic",
                "away": "Dinamo Zagreb"
            },
            "pot4": {
                "home": "Sturm Graz",
                "away": "Bologna"
            }
        },
        "RBLeipzig": {
            "pot1": {
                "home": "Liverpool",
                "away": "Inter Milan"
            },
            "pot2": {
                "home": "Juventus",
                "away": "Atlético Madrid"
            },
            "pot3": {
                "home": "Sporting CP",
                "away": "Celtic"
            },
            "pot4": {
                "home": "Aston Villa",
                "away": "Sturm Graz"
            }
        },
        "BarcelonaFC": {
            "pot1": {
                "home": "FC Bayern München",
                "away": "Borussia Dortmund"
            },
            "pot2": {
                "home": "Atalanta",
                "away": "Benfica"
            },
            "pot3": {
                "home": "Young Boys",
                "away": "FK Bodø/Glimt"
            },
            "pot4": {
                "home": "Brest",
                "away": "AS Monaco"
            }
        }
    },
    "pot2": {
        "Bayer04Leverkusen": {
            "pot1": {
                "home": "Inter Milan",
                "away": "Liverpool"
            },
            "pot2": {
                "home": "AC Milan",
                "away": "Atlético Madrid"
            },
            "pot3": {
                "home": "FC Red Bull Salzburg",
                "away": "Feyenoord"
            },
            "pot4": {
                "home": "Sparta Praha",
                "away": "Brest"
            }
        },
        "AtléticoMadrid": {
            "pot1": {
                "home": "RB Leipzig",
                "away": "Paris Saint Germain"
            },
            "pot2": {
                "home": "Bayer 04 Leverkusen",
                "away": "Benfica"
            },
            "pot3": {
                "home": "Lille",
                "away": "FC Red Bull Salzburg"
            },
            "pot4": {
                "home": "Midtjylland",
                "away": "Sparta Praha"
            }
        },
        "Atalanta": {
            "pot1": {
                "home": "Real Madrid",
                "away": "Barcelona FC"
            },
            "pot2": {
                "home": "Arsenal",
                "away": "Shakhtar Donetsk"
            },
            "pot3": {
                "home": "Celtic",
                "away": "Young Boys"
            },
            "pot4": {
                "home": "Sturm Graz",
                "away": "VfB Stuttgart"
            }
        },
        "Juventus": {
            "pot1": {
                "home": "Manchester City",
                "away": "RB Leipzig"
            },
            "pot2": {
                "home": "Benfica",
                "away": "Club Brugge"
            },
            "pot3": {
                "home": "PSV",
                "away": "Lille"
            },
            "pot4": {
                "home": "VfB Stuttgart",
                "away": "Aston Villa"
            }
        },
        "Benfica": {
            "pot1": {
                "home": "Barcelona FC",
                "away": "FC Bayern München"
            },
            "pot2": {
                "home": "Atlético Madrid",
                "away": "Juventus"
            },
            "pot3": {
                "home": "Feyenoord",
                "away": "FK Bodø/Glimt"
            },
            "pot4": {
                "home": "Bologna",
                "away": "AS Monaco"
            }
        },
        "Arsenal": {
            "pot1": {
                "home": "Paris Saint Germain",
                "away": "Inter Milan"
            },
            "pot2": {
                "home": "Shakhtar Donetsk",
                "away": "Atalanta"
            },
            "pot3": {
                "home": "Dinamo Zagreb",
                "away": "Sporting CP"
            },
            "pot4": {
                "home": "AS Monaco",
                "away": "Girona"
            }
        },
        "ClubBrugge": {
            "pot1": {
                "home": "Borussia Dortmund",
                "away": "Manchester City"
            },
            "pot2": {
                "home": "Juventus",
                "away": "AC Milan"
            },
            "pot3": {
                "home": "Sporting CP",
                "away": "Celtic"
            },
            "pot4": {
                "home": "Aston Villa",
                "away": "Sturm Graz"
            }
        },
        "ShakhtarDonetsk": {
            "pot1": {
                "home": "FC Bayern München",
                "away": "Borussia Dortmund"
            },
            "pot2": {
                "home": "Atalanta",
                "away": "Arsenal"
            },
            "pot3": {
                "home": "Young Boys",
                "away": "PSV"
            },
            "pot4": {
                "home": "Brest",
                "away": "Bologna"
            }
        },
        "AC Milan": {
            "pot1": {
                "home": "Liverpool",
                "away": "Real Madrid"
            },
            "pot2": {
                "home": "Club Brugge",
                "away": "Bayer 04 Leverkusen"
            },
            "pot3": {
                "home": "Dinamo Zagreb",
                "away": "Girona"
            },
            "pot4": {
                "home": "Midtjylland",
                "away": "FK Bodø/Glimt"
            }
        },
    },
    "pot3": {
        "Feyenoord": {
            "pot1": {
                "home": "FC Bayern München",
                "away": "Manchester City"
            },
            "pot2": {
                "home": "Bayer 04 Leverkusen",
                "away": "Benfica"
            },
            "pot3": {
                "home": "FC Red Bull Salzburg",
                "away": "Lille"
            },
            "pot4": {
                "home": "Sparta Praha",
                "away": "Girona"
            }
        },
        "SportingCP": {
            "pot1": {
                "home": "Manchester City",
                "away": "RB Leipzig"
            },
            "pot2": {
                "home": "Arsenal",
                "away": "Club Brugge"
            },
            "pot3": {
                "home": "Lille",
                "away": "PSV"
            },
            "pot4": {
                "home": "Bologna",
                "away": "Sturm Graz"
            }
        },
        "PSV": {
            "pot1": {
                "home": "Liverpool",
                "away": "Paris Saint Germain"
            },
            "pot2": {
                "home": "Shakhtar Donetsk",
                "away": "Juventus"
            },
            "pot3": {
                "home": "Sporting CP",
                "away": "FK Bodø/Glimt"
            },
            "pot4": {
                "home": "Girona",
                "away": "Brest"
            }
        },
        "DinamoZagreb": {
            "pot1": {
                "home": "Borussia Dortmund",
                "away": "FC Bayern München"
            },
            "pot2": {
                "home": "AC Milan",
                "away": "Arsenal"
            },
            "pot3": {
                "home": "Celtic",
                "away": "FC Red Bull Salzburg"
            },
            "pot4": {
                "home": "AS Monaco",
                "away": "Midtjylland"
            }
        },
        "FCRedBullSalzburg": {
            "pot1": {
                "home": "Paris Saint Germain",
                "away": "Real Madrid"
            },
            "pot2": {
                "home": "Atlético Madrid",
                "away": "Bayer 04 Leverkusen"
            },
            "pot3": {
                "home": "Dinamo Zagreb",
                "away": "Feyenoord"
            },
            "pot4": {
                "home": "Brest",
                "away": "Sparta Praha"
            }
        },
        "Lille": {
            "pot1": {
                "home": "Real Madrid",
                "away": "Liverpool"
            },
            "pot2": {
                "home": "Juventus",
                "away": "Atlético Madrid"
            },
            "pot3": {
                "home": "Feyenoord",
                "away": "Sporting CP"
            },
            "pot4": {
                "home": "Sturm Graz",
                "away": "Bologna"
            }
        },
        "FKBodø/Glimt": {
            "pot1": {
                "home": "Barcelona FC",
                "away": "Inter Milan"
            },
            "pot2": {
                "home": "Benfica",
                "away": "AC Milan"
            },
            "pot3": {
                "home": "PSV",
                "away": "Young Boys"
            },
            "pot4": {
                "home": "VfB Stuttgart",
                "away": "AS Monaco"
            }
        },
        "YoungBoys": {
            "pot1": {
                "home": "Inter Milan",
                "away": "Barcelona FC"
            },
            "pot2": {
                "home": "Atalanta",
                "away": "Shakhtar Donetsk"
            },
            "pot3": {
                "home": "FK Bodø/Glimt",
                "away": "Celtic"
            },
            "pot4": {
                "home": "Aston Villa",
                "away": "VfB Stuttgart"
            }
        },
        "Celtic": {
            "pot1": {
                "home": "RB Leipzig",
                "away": "Borussia Dortmund"
            },
            "pot2": {
                "home": "Club Brugge",
                "away": "Atalanta"
            },
            "pot3": {
                "home": "Young Boys",
                "away": "Dinamo Zagreb"
            },
            "pot4": {
                "home": "Midtjylland",
                "away": "Aston Villa"
            }
        },
    },
    "pot4": {
        "Midtjylland": {
            "pot1": {
                "home": "Manchester City",
                "away": "FC Bayern München"
            },
            "pot2": {
                "home": "AC Milan",
                "away": "Atlético Madrid"
            },
            "pot3": {
                "home": "Dinamo Zagreb",
                "away": "Celtic"
            },
            "pot4": {
                "home": "VfB Stuttgart",
                "away": "Girona"
            }
        },
        "ASMonaco": {
            "pot1": {
                "home": "Barcelona FC",
                "away": "Inter Milan"
            },
            "pot2": {
                "home": "Benfica",
                "away": "Arsenal"
            },
            "pot3": {
                "home": "FK Bodø/Glimt",
                "away": "Dinamo Zagreb"
            },
            "pot4": {
                "home": "Aston Villa",
                "away": "Bologna"
            }
        },
        "SpartaPraha": {
            "pot1": {
                "home": "Inter Milan",
                "away": "Manchester City"
            },
            "pot2": {
                "home": "Atlético Madrid",
                "away": "Bayer 04 Leverkusen"
            },
            "pot3": {
                "home": "FC Red Bull Salzburg",
                "away": "Feyenoord"
            },
            "pot4": {
                "home": "Brest",
                "away": "VfB Stuttgart"
            }
        },
        "AstonVilla": {
            "pot1": {
                "home": "FC Bayern München",
                "away": "RB Leipzig"
            },
            "pot2": {
                "home": "Juventus",
                "away": "Club Brugge"
            },
            "pot3": {
                "home": "Celtic",
                "away": "Young Boys"
            },
            "pot4": {
                "home": "Bologna",
                "away": "AS Monaco"
            }
        },
        "Bologna": {
            "pot1": {
                "home": "Borussia Dortmund",
                "away": "Liverpool"
            },
            "pot2": {
                "home": "Shakhtar Donetsk",
                "away": "Benfica"
            },
            "pot3": {
                "home": "Lille",
                "away": "Sporting CP"
            },
            "pot4": {
                "home": "AS Monaco",
                "away": "Aston Villa"
            }
        },
        "Girona": {
            "pot1": {
                "home": "Liverpool",
                "away": "Paris Saint Germain"
            },
            "pot2": {
                "home": "Arsenal",
                "away": "AC Milan"
            },
            "pot3": {
                "home": "Feyenoord",
                "away": "PSV"
            },
            "pot4": {
                "home": "Midtjylland",
                "away": "Sturm Graz"
            }
        },
        "VfBStuttgart": {
            "pot1": {
                "home": "Paris Saint Germain",
                "away": "Real Madrid"
            },
            "pot2": {
                "home": "Atalanta",
                "away": "Juventus"
            },
            "pot3": {
                "home": "Young Boys",
                "away": "FK Bodø/Glimt"
            },
            "pot4": {
                "home": "Sparta Praha",
                "away": "Midtjylland"
            }
        },
        "SturmGraz": {
            "pot1": {
                "home": "RB Leipzig",
                "away": "Borussia Dortmund"
            },
            "pot2": {
                "home": "Club Brugge",
                "away": "Atalanta"
            },
            "pot3": {
                "home": "Sporting CP",
                "away": "Lille"
            },
            "pot4": {
                "home": "Girona",
                "away": "Brest"
            }
        },
        "Brest": {
            "pot1": {
                "home": "Real Madrid",
                "away": "Barcelona FC"
            },
            "pot2": {
                "home": "Bayer 04 Leverkusen",
                "away": "Shakhtar Donetsk"
            },
            "pot3": {
                "home": "PSV",
                "away": "FC Red Bull Salzburg"
            },
            "pot4": {
                "home": "Sturm Graz",
                "away": "Sparta Praha"
            }
        }
    }
};