import React, { useState } from "react";
import './knockout-bracket.css';
import { parseMinute } from "./MatchResultPopup";

const KnockoutBracket = ({ currentLeg, knockoutStage, knockoutResults, fixturesWithDates, handleSimulateKnockout, advanceToNextRound, logos }) => {
    const getTeamLogo = (teamName) => {
        const logo = logos.find((logo) => logo.club === teamName);
        return logo ? logo.logo : '';
    };

    const getRound = (stage) => {
        if (stage > 8) {
            return `Round of ${stage}`; 
        } else if (stage === 8) {
            return 'Quarter Finals';
        } else if (stage === 4) {
            return 'Semi Finals';
        } else if (stage === 2) {
            return 'Final';
        }
        return '';
    }

    return (
        <div className="knockout-container">
            {currentLeg < 2 && (
                <button
                    onClick={handleSimulateKnockout}
                    className="simulate-button"
                >
                    {knockoutStage === 1
                        ? 'Play Final'
                        : currentLeg === 0
                            ? 'Simulate First Leg'
                            : 'Simulate Second Leg'}
                </button>
            )}

            {currentLeg === 2 && (
                <button
                    onClick={advanceToNextRound}
                    className="advance-round-button"
                >Simulate {getRound(2 ** (knockoutStage - 1))}</button>
            )}
            <div className="knockout-bracket">
                {Object.keys(fixturesWithDates).sort((a, b) => b - a).map((stageKey) => {
                    if (stageKey === '2') {
                        const finalFixture = fixturesWithDates[stageKey][0];
                        //console.log('Final fixture:', finalFixture);

                        const team1Logo = getTeamLogo(finalFixture.team1);
                        const team2Logo = getTeamLogo(finalFixture.team2);

                        let score, winnerNote = '', victor;

                        if (knockoutResults[stageKey]) {
                            const match = knockoutResults[stageKey];
                            const homeGoals = match.homeGoals;
                            const awayGoals = match.awayGoals;
                            if (match.penalties) {
                                score = `${homeGoals}(${match.penalties.team1Score}) - ${awayGoals}(${match.penalties.team2Score})`;
                            } else {
                                score = `${homeGoals} - ${awayGoals}`;
                            }

                            if (
                                match.events.some((event) => event.minute > 90) &&
                                !match.penalties
                            ) {
                                winnerNote = ' (a.e.t.)';
                            }

                            //Check for victor
                            if (homeGoals > awayGoals) {
                                victor = finalFixture.team1;
                            } else if (awayGoals > homeGoals) {
                                victor = finalFixture.team2;
                            } else {
                                if (match.penalties) {
                                    return match.penalties.team1Score > match.penalties.team2Score ? finalFixture.team1 : finalFixture.team2;
                                }
                                return '';
                            }
                        }

                        return (
                            <div key={stageKey} className="knockout-stage final">
                                <div className="round-title">{getRound(parseInt(stageKey))}</div>
                                <div className="header-row">
                                    <div className="header-cell">Team 1</div>
                                    <div className="header-cell">Team 2</div>
                                    <div className="header-cell">Score</div>
                                </div>
                                <div className="knockout-fixture-row">
                                    <div className="knockout-team-cell">
                                        <img src={team1Logo} alt={`${finalFixture.team1} logo`} className="team-logo" />
                                        <span>{finalFixture.team1}</span>
                                    </div>
                                    <div className="knockout-team-cell">
                                        <img src={team2Logo} alt={`${finalFixture.team2} logo`} className="team-logo" />
                                        <span>{finalFixture.team2}</span>
                                    </div>
                                    <div className="knockout-score-cell">{score || ''}{winnerNote}</div>
                                </div>
                                {victor !== undefined &&
                                    <div className="victor">
                                        <img src={getTeamLogo(victor)} alt={`${victor} logo`} style={{ width: "50px", height: "50px" }} />
                                        <h2><strong>{victor.toUpperCase()}</strong></h2>
                                        <p>WINS THE CHAMPIONS LEAGUE!</p>
                                    </div>
                                }
                            </div>
                        );
                    }

                    return (
                        <div key={stageKey} className="knockout-stage">
                            <div className="round-title">{getRound(parseInt(stageKey))}</div>
                            <div className="header-row">
                                <div className="header-cell">Team 1</div>
                                <div className="header-cell">Team 2</div>
                                <div className="header-cell">1st Leg</div>
                                <div className="header-cell">2nd Leg</div>
                                <div className="header-cell">Aggregate</div>
                            </div>
                            {fixturesWithDates[stageKey].map((fixture, index) => {
                                const team1Logo = getTeamLogo(fixture.team1);
                                const team2Logo = getTeamLogo(fixture.team2);

                                let firstLegScore, secondLegScore, aggregateScore;
                                let winnerNote = '';

                                if (knockoutResults && knockoutResults[stageKey]?.firstLegResults.length > 0) {
                                    const firstLeg = knockoutResults[stageKey].firstLegResults[index];
                                    const secondLeg = knockoutResults[stageKey].secondLegResults[index];

                                    // Scores for the first leg
                                    firstLegScore = `${firstLeg.homeGoals} - ${firstLeg.awayGoals}`;

                                    // Scores for the second leg, if currentLeg === 2
                                    if (currentLeg === 2 || 2 ** knockoutStage !== stageKey) {
                                        const team1SecondLegScore =
                                            secondLeg.awayGoals -
                                            knockoutResults[stageKey].firstLegResults[index].homeGoals;
                                        const team2SecondLegScore =
                                            secondLeg.homeGoals -
                                            knockoutResults[stageKey].firstLegResults[index].awayGoals;

                                        secondLegScore = `${team1SecondLegScore} - ${team2SecondLegScore}`;

                                        if (secondLeg.penalties) {
                                            aggregateScore = `${secondLeg.awayGoals}(${secondLeg.penalties.team2Score}) - ${secondLeg.homeGoals}(${secondLeg.penalties.team1Score})`;
                                        } else {
                                            aggregateScore = `${secondLeg.awayGoals} - ${secondLeg.homeGoals}`;
                                        }

                                        if (
                                            secondLeg.events.some((event) => event.minute > 90) &&
                                            !secondLeg.penalties
                                        ) {
                                            winnerNote = ' (a.e.t.)';
                                        }
                                    }
                                }

                                return (
                                    <div
                                        key={index}
                                        className="knockout-fixture-row">
                                            <div className="knockout-team-cell">
                                                <img src={team1Logo} alt={`${fixture.team1} logo`} className="team-logo" />
                                                <span>{fixture.team1}</span>
                                            </div>
                                            <div className="knockout-team-cell">
                                                <img src={team2Logo} alt={`${fixture.team2} logo`} className="team-logo" />
                                                <span>{fixture.team2}</span>
                                            </div>
                                        <div className="knockout-score-cell">{firstLegScore || ''}</div>
                                        <div className="knockout-score-cell">{(currentLeg === 2 || 2 ** knockoutStage !== parseInt(stageKey)) ? secondLegScore || '' : ''}</div>
                                        <div className="knockout-aggregate-cell">
                                            {(currentLeg === 2 || 2 ** knockoutStage !== parseInt(stageKey))
                                                ? `${aggregateScore}${winnerNote}`
                                                : firstLegScore}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default KnockoutBracket;