import React, { useState } from "react";
import { createPortal } from "react-dom";
import "./injury-display.css";
import injuryIcon from "../resources/player_injury.png";

const formatDate = (dateStr) => {
    if (!dateStr || dateStr === "Unknown") return "Unknown";

    const date = new Date(dateStr);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    return `${day} ${month}, ${year}`;
};

const InjuryDisplay = ({ player }) => {
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0, alignRight: false });
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = (event) => {
        const rect = event.target.getBoundingClientRect();
        const tooltipWidth = 200; // Estimated tooltip width
        const viewportWidth = window.innerWidth;

        // Check if tooltip will overflow the right edge
        const willOverflowRight = rect.left + tooltipWidth > viewportWidth;

        setTooltipPosition({
            x: willOverflowRight ? rect.right - tooltipWidth + 10 : rect.left + rect.width / 2,
            y: rect.top + window.scrollY - 40,
            alignRight: willOverflowRight,
        });

        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    return (
        <>
            <div
                className="injury-container"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <img
                    src={injuryIcon}
                    alt="Injured"
                    className="injury-icon"
                />
            </div>

            {showTooltip && createPortal(
                <div
                    className={`injury-tooltip ${tooltipPosition.alignRight ? "tooltip-right" : ""}`}
                    style={{ top: tooltipPosition.y, left: tooltipPosition.x }}
                >
                    Injured with <u>{player.injuryStatus.type === "Ill" ? "Illness" : player.injuryStatus.type}</u>
                    <br />
                    Unavailable until {formatDate(player.injuryStatus.until)}
                </div>,
                document.body // Moves tooltip to the body to prevent clipping
            )}
        </>
    );
};

export default InjuryDisplay;