import { findBestFormation } from "../GameSimulation/FindBestFormation";
import { getFullName } from "../GameSimulation/LeagueSimulator";
import unaccent from "unaccent";

const enhancedUnaccent = (text) => {
    // First, use unaccent to remove most accents
    let normalizedText = unaccent(text);

    // Manually replace any specific characters missed by unaccent
    normalizedText = normalizedText.replace(/ú/g, 'u');

    return normalizedText;
};

const getLeagueName = (leagueId) => {
    if (leagueId === 13) {
        return "Premier League";
    } else if (leagueId === 19) {
        return "Bundesliga";
    } else if (leagueId === 53) {
        return "La Liga";
    }

    return "error";
}

const formatFileName = (leagueName) => {
    return leagueName.toLowerCase().replace(/\s+/g, "-") + "-injuries.html";
};

const parseInjuries = async (leagueId) => {
    const leagueName = getLeagueName(leagueId);
    const fileName = formatFileName(leagueName);
    //console.log(`File: ./${fileName}`)

    try {
        const response = await fetch(`/${fileName}`); // Update with correct file path
        if (!response.ok) throw new Error(`Failed to fetch file: ${response.statusText}`);

        const fileText = await response.text(); // Read the file contents as text
        const parser = new DOMParser();
        const doc = parser.parseFromString(fileText, "text/html");

        const rows = doc.querySelectorAll("tr.odd, tr.even");
        const currentDate = new Date();
        let playersList = [];

        rows.forEach(row => {
            const cols = row.querySelectorAll("td");
            if (cols.length >= 4) {  // Ensure row has enough columns
                let playerName = cols[2].innerText.trim();
                const injuryType = cols[5].innerText.trim();
                let untilDateText = cols[6].innerText.trim();
                //console.log("Until date text:", untilDateText);
                let formattedDate = "";
                if (untilDateText) {
                    try {
                        const injuryDate = new Date(Date.parse(untilDateText)); // Correctly parse "Mar 8, 2025"
                        if (injuryDate < currentDate) {
                            return; // Skip players with previous return dates
                        }
                        formattedDate = injuryDate.toISOString().split("T")[0];
                    } catch (error) {
                        formattedDate = ""; // If date is invalid, leave it blank
                    }
                }

                playersList.push({ name: playerName, type: injuryType, until: formattedDate });
            }
        });

        //console.log("Injured players:", playersList);
        return playersList;
    } catch (error) {
        console.error("Error loading injury file:", error);
        return [];
    }
};

/**
 * Function to check if a player is injured and adds to injuredPlayersInTeam array
 * @param {*} injuredPlayers 
 * @param {*} injuredPlayersInTeam 
 * @param {*} player 
 * @param {*} actualName 
 * @returns true if player is injured, false otherwise
 */
const findInjury = (injuredPlayers, injuredPlayersInTeam, player, actualName) => {
    const injuryRecord = injuredPlayers.find(injured => enhancedUnaccent(injured.name) === enhancedUnaccent(actualName));
    if (injuryRecord) {
        player.injuryStatus = {
            type: injuryRecord.type,
            until: injuryRecord.until || "Unknown"
        };
        injuredPlayersInTeam.push({ ...player, type: "reserves" });
        return true; // Remove from bench
    } else {
        player.injuryStatus = { type: '', until: '' };
    }

    return false;
};

const manageInjuriesInTeam = async (team) => {
    const injuredPlayers = await parseInjuries(team.leagueId);
    //console.log("Injured players:", injuredPlayers);
    const injuredPlayersInTeam = [];
    Object.keys(team.positionsData).forEach(positionKey => {
        let player = team.positionsData[positionKey];
        const actualName = getFullName(player.name, player.fullName, player.nationality);
        //console.log(`${player.name} actual name: ${actualName}`);
        const isInjured = findInjury(injuredPlayers, injuredPlayersInTeam, player, actualName.trim());

        if (isInjured) {
            player.currentPosition = { name: '', key: '' };
            delete team.positionsData[positionKey];
        }
    });

    // Filter injured players from bench
    team.bench = team.bench.filter(player => {
        const actualName = getFullName(player.name, player.fullName, player.nationality);
        const isInjured = findInjury(injuredPlayers, injuredPlayersInTeam, player, actualName);
        return !isInjured; // Keep player
    });

    // Filter injured players from reserves
    team.reserves = team.reserves.filter(player => {
        const actualName = getFullName(player.name, player.fullName, player.nationality);
        const isInjured = findInjury(injuredPlayers, injuredPlayersInTeam, player, actualName);
        return !isInjured; // Keep player
    });

    //console.log("injured players in team:", injuredPlayersInTeam);
    const bestTeam = findBestFormation(
        Object.values(team.positionsData),
        team.bench,
        team.reserves,
        team.dropFormation
    );

    // Sort injured players: earliest return date first, "Unknown" last
    injuredPlayersInTeam.sort((a, b) => {
        if (a.injuryStatus.until === "Unknown") return 1; // Push "Unknown" to the end
        if (b.injuryStatus.until === "Unknown") return -1;

        return new Date(a.injuryStatus.until) - new Date(b.injuryStatus.until); // Sort by date
    });

    // Push injured players back into reserves
    bestTeam.reserves.push(...injuredPlayersInTeam)

    // console.log("Best team:", bestTeam);

    const updatedTeam = {
        bench: bestTeam.bench,
        reserves: bestTeam.reserves,
        bestFormation: bestTeam.bestFormation
    };
    return updatedTeam;
};

export { manageInjuriesInTeam };