import axios from 'axios';
import {load} from 'cheerio';
import { calculateOverall } from './OverallCalculation';
import { backendUrl } from './ItemTypes';

const getNewPlayerStats = async (playerIds) => {
    try {
        //const url = `https://footballcustomformation.com/php_backend/web_server.php/FIFAFormationCreator/player/${playerId}`;
        //const url = `http://localhost:3001/FIFAFormationCreator/player/${playerId}`;
        const fullUrl = `${backendUrl}/api/crawler/scrape-players`;
        const response = await axios.post(fullUrl, { playerIds }, {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        });
        return response.data;
    } catch (error) { // Corrected this line
        console.error('Data unable to be retrieved from add new player', error); // Corrected this line
        return '';
    }
};

const calculateAge = (birthday) => {
    const birthdayDate = new Date(birthday);
    const today = new Date();
    let age = today.getFullYear() - birthdayDate.getFullYear();
    const m = today.getMonth() - birthdayDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthdayDate.getDate())) {
        age--;
    }
    const ageString = `${birthday}; ${age}`;

    return ageString;
}

const addNewPlayers = async (playerIds) => {
    const newPlayers = [];
    try {
        const htmlArray = await getNewPlayerStats(playerIds);
        console.log("Html array in addNewPlayer:", htmlArray);
        for (const playerHtml of htmlArray) {
            const html = playerHtml.html;
            const $ = load(html);
            const attributes = {}; // Object to hold all attributes

            //Find birthday:
            const profile = $('.profile.clearfix');
            const fullName = profile.find('h1').text();
            //console.log('Name', fullName);
            const playerInfoText = profile.find('p').text();

            // Use a regular expression to extract the birthday
            const birthdayMatch = playerInfoText.match(/\(([^)]+)\)/);
            const birthday = birthdayMatch ? birthdayMatch[1] : 'Not found';
            //console.log('Birthday', birthday);

            const heightMatch = playerInfoText.match(/\b\d{3}cm\s\/\s\d+'\d{1,2}"/);
            const height = heightMatch ? heightMatch[0] : '17x cm';

            const weightMatch = playerInfoText.match(/\b\d+kg \/ \d+lbs\b/);
            const weight = weightMatch ? weightMatch[0] : '5x kg';

            const parsedBirthday = new Date(birthday);
            const formattedBirthday = parsedBirthday.toISOString().split('T')[0];
            //console.log('Formatted birthday', formattedBirthday);

            $('div.col').find('p').each((i, el) => {
                const textContent = $(el).contents().filter(function () {
                    return this.type === 'text';
                }).text().trim();

                const label = $(el).find('label').text();

                if (label) {
                    attributes[label] = textContent;
                }
            });

            // Assuming all attributes are within a div.grid.attribute block
            $('div.grid.attribute').each((i, elem) => {
                // Find all <p> elements within this block
                $(elem).find('p').each((j, pElem) => {
                    // Assuming the structure is always <p><em>value</em> <span>attribute name</span></p>
                    let attributeName = $(pElem).find('span').text().trim(); // Get the attribute name
                    attributeName = attributeName.replace(/\+\d+\s*/, '').trim();
                    const attributeValue = $(pElem).find('em').text().trim(); // Get the attribute value

                    attributes[attributeName] = attributeValue; // Store in the object
                });
            });

            //Data for player image
            const paddedId = playerHtml.playerId.toString().padStart(6, '0');
            const part1 = paddedId.substring(0, 3);
            const part2 = paddedId.substring(3);
            const clubElement = $('.col').has('h5:contains("Club")').find('a[href*="/team/"]').first();
            const club = clubElement.text().trim();
            const clubLogo = clubElement.find('img.avatar').attr('data-src');
            const kitNumbers = $('.col').find('p:contains("Kit number")').contents().filter(function () {
                return this.type === 'text'; // Get only the text node
            }).text().trim();
            const kitNumberArray = kitNumbers.split(' ').map(num => num.trim());
            const clubKitNumber = kitNumberArray[0] || 0;
            const nationKitNumber = kitNumberArray[1] || 0;
            const positions = profile.find('span.pos').map(function () {
                return $(this).text();
            }).get().join('; ');

            const newPlayer = {
                id: playerHtml.playerId,
                name: $('h1.ellipsis').text(),
                fullName: fullName,
                age: calculateAge(birthday),
                height: height,
                weight: weight,
                photo: `https://cdn.sofifa.net/players/${part1}/${part2}/25_120.png`,
                nationality: profile.find('a[rel="nofollow"]').first().attr('title'),
                flag: profile.find('img.flag').first().attr('data-src'),
                countryNumber: nationKitNumber,
                overall: $('.grid .col').find('.sub:contains("Overall rating")').siblings('em').attr('title'),
                potential: $('.grid .col').find('.sub:contains("Potential")').siblings('em').attr('title'),
                position: positions,
                currentPosition: '',
                club: club,
                clubLogo: clubLogo,
                clubNumber: clubKitNumber,
                prefFoot: attributes['Preferred foot'],
                weakFoot: attributes['Weak foot'],
                skillMoves: attributes['Skill moves'],
                intRep: attributes['International reputation'],
                acceleration: attributes['Acceleration'],
                sprintSpeed: attributes['Sprint speed'],
                pace: Math.round(attributes['Acceleration'] * 0.45 + attributes['Sprint speed'] * 0.55),
                positioning: attributes['Att. Position'],
                finishing: attributes['Finishing'],
                shotPower: attributes['Shot power'],
                longShots: attributes['Long shots'],
                volleys: attributes['Volleys'],
                penalties: attributes['Penalties'],
                fkAccuracy: attributes['FK Accuracy'],
                shooting: Math.round(attributes['Att. Position'] * 0.05 + attributes['Finishing'] * 0.45 + attributes['Long shots'] * 0.2 + attributes['Penalties'] * 0.05 + attributes['Shot power'] * 0.2 + attributes['Volleys'] * 0.05),
                vision: attributes['Vision'],
                crossing: attributes['Crossing'],
                shortPassing: attributes['Short passing'],
                longPassing: attributes['Long passing'],
                curve: attributes['Curve'],
                passing: Math.round(attributes['Vision'] * 0.2 + attributes['Crossing'] * 0.2 + attributes['Curve'] * 0.05 + attributes['FK Accuracy'] * 0.05 + attributes['Long passing'] * 0.15 + attributes['Short passing'] * 0.35),
                agility: attributes['Agility'],
                balance: attributes['Balance'],
                reactions: attributes['Reactions'],
                ballControl: attributes['Ball control'],
                skill_dribbling: attributes['Dribbling'],
                dribbling: Math.round(attributes['Agility'] * 0.1 + attributes['Balance'] * 0.05 + attributes['Reactions'] * 0.05 + attributes['Ball control'] * 0.3 + attributes['Dribbling'] * 0.5),
                composure: attributes['Composure'],
                interceptions: attributes['Interceptions'],
                headingAccuracy: attributes['Heading accuracy'],
                marking: attributes['Defensive awareness'],
                standingTackle: attributes['Standing tackle'],
                slidingTackle: attributes['Sliding tackle'],
                defending: Math.round(attributes['Interceptions'] * 0.2 + attributes['Heading accuracy'] * 0.1 + attributes['Defensive awareness'] * 0.3 + attributes['Sliding tackle'] * 0.1 + attributes['Standing tackle'] * 0.3),
                jumping: attributes['Jumping'],
                stamina: attributes['Stamina'],
                strength: attributes['Strength'],
                aggression: attributes['Aggression'],
                physical: Math.round(attributes['Aggression'] * 0.2 + attributes['Jumping'] * 0.05 + attributes['Stamina'] * 0.25 + attributes['Strength'] * 0.5),
                gkDiving: attributes['GK Diving'],
                gkHandling: attributes['GK Handling'],
                gkKicking: attributes['GK Kicking'],
                gkReflex: attributes['GK Reflexes'],
                gkSpeed: 5,
                gkPos: attributes['GK Positioning'],
                LS: '', ST: '', RS: '',
                CF: '', RF: '', LF: '',
                LW: '', RW: '', LAM: '', CAM: '', RAM: '',
                LM: '', LCM: '', RCM: '', RM: '', CM: '',
                LB: '', LWB: '',
                LDM: '', CDM: '', RDM: '',
                RB: '', RWB: '',
                LCB: '', CB: '', RCB: '',
                GK: '', goals: 0, assists: 0, cleanSheets: 0, form: 'Okay',
            };

            calculateOverall(newPlayer);
            newPlayers.push(newPlayer);
        }
        return newPlayers;
    } catch (error) {
        console.error("Error loading player data: ", error);
        return null;
    }
}

//Set functions are setQueue, setBench, setReserves, setPlayerData, and setEditingTeam
export const getPlayersFromTeam = async (team, id, playerData, setFunctions) => {
    console.log(`Team id: ${id}`);
    try {
        const url = `${backendUrl}/api/crawler/scrape-team?teamId=${id}`;
        const response = await axios.post(url);
        const html = response.data;
        const $ = load(html);
        const players = {
            teamId: id,
            teamName: team.name,
            starting: [],
            sub: [],
            res: []
        };

        $('table:first').find('tbody tr').each((index, element) => {
            const playerRow = $(element);
            const role = playerRow.attr('class');
            const playerName = playerRow.find('td a').first().text().trim();
            const playerLink = playerRow.find('td a').first().attr('href');
            const playerIdMatch = playerLink.match(/\/player\/(\d+)\//);
            const playerId = playerIdMatch ? playerIdMatch[1] : null;
            if (playerId) {
                const playerInfo = { name: playerName, id: playerId };
                // Add player to the corresponding category based on their role
                if (role.includes('starting')) {
                    players.starting.push(playerInfo);
                } else if (role.includes('sub')) {
                    players.sub.push(playerInfo);
                } else if (role.includes('res')) {
                    players.res.push(playerInfo);
                }
            }
        });
        //console.log(players);
        const newPlayers = [];
        const processPlayers = async (playerList, type) => {
            const queue = [];
            for (const player of playerList) {
                let currentPlayer = playerData.find(p => p.id === player.id);
                if (!currentPlayer) {
                    newPlayers.push({ id: player.id, type });
                } else {
                    if (currentPlayer.club !== team.name || currentPlayer.clubLogo !== team.image && team.type === 'club') {
                        currentPlayer.clubLogo = team.image;
                        currentPlayer.club = team.name;
                    }
                    currentPlayer.type = type;

                    queue.push(currentPlayer);
                }
            }
            return queue;
        };

        const startingQueue = await processPlayers(players.starting, 'queue');
        setFunctions.setQueue(startingQueue);

        const benchQueue = await processPlayers(players.sub, 'bench');
        setFunctions.setBench(benchQueue);

        const reserveQueue = await processPlayers(players.res, 'reserves');
        setFunctions.setReserves(reserveQueue);

        if (newPlayers.length > 0) {
            const newPlayerIds = newPlayers.map(player => player.id);
            const addedPlayers = await addNewPlayers(newPlayerIds);
            console.log("Added players when creating team:", addedPlayers);

            if (addedPlayers && addedPlayers.length > 0) {
                const queueUpdates = { queue: [], bench: [], reserves: [] };
                addedPlayers.forEach(player => {
                    const type = newPlayers.find(p => p.id === player.id)?.type;
                    if (type) {
                        player.type = type;
                        if (player.club !== team.name || player.clubLogo !== team.image) {
                            player.clubLogo = team.image;
                            player.club = team.name;
                        }
                        queueUpdates[type].push(player);
                    }
                });

                setFunctions.setQueue([...startingQueue, ...queueUpdates.queue]);
                setFunctions.setBench([...benchQueue, ...queueUpdates.bench]);
                setFunctions.setReserves([...reserveQueue, ...queueUpdates.reserves]);
            }
        }
        setFunctions.setEditingTeam(null);
    } catch (error) {
        console.error('Cannot find link for team', error);
    }
};

export default addNewPlayers;