import { manageInjuriesInTeam } from "../../Important_HTML/InjuredPlayers";
import { getMatchDetailsByMinute } from "../LeagueSimulation";
import { parseMinute } from "../MatchResultPopup";
import { assignCaptain, simulateMatch } from "../SimulateMatch";
import { positionParams } from "../../CustomFormation";

const getPositionType = (positionName) => {
    if (positionName.includes('B') && (!positionName.includes('WB'))) return 'Defender';
    if (positionName.includes('CM') || positionName.includes('LM') || positionName.includes('RM') || positionName.includes('WB')) return 'Midfielder';
    if (positionName.endsWith('AM')) return 'aMidfielder';
    if (positionName.includes('S') || positionName.includes('W')) return 'Striker';
    if (positionName.endsWith('DM')) return 'dMidfielder';
}

const convertPositionNames = (positionNames, currentPlayers, formation) => {
    const updatedPositions = {};

    for (let i = 0; i < positionNames.length; i++) {
        const player = currentPlayers[positionNames[i]];
        const position = positionNames[i];

        if (positionNames.includes(position) && player) {
            //console.log('Current position:', positionNames[i]);
            if (position === 'GK') {
                updatedPositions['Goalkeeper-0'] = player;
                player.currentPosition = { key: 'Goalkeeper-0', name: position };
            } else {
                const param = positionParams[getPositionType(position)]; //formation variable
                for (let j = 0; j < formation[param]; j++) {
                    const positionKey = `${getPositionType(position)}-${j}`;
                    if (!updatedPositions[positionKey]) {
                        updatedPositions[positionKey] = player;
                        player.currentPosition = { key: positionKey, name: position };
                        //console.log(player.name, 'has been added to', positionKey);
                        break;
                    }
                }
            }
        }
    }

    return updatedPositions;
};

const getScorelineStats = (aggregateResults) => {
    const scoreCount = {};
    let mostFrequentScoreline = null;
    let maxCount = 0;

    let highestDiffScoreline = null;
    let maxGoalDifference = 0;
    let maxTotalGoals = 0;

    aggregateResults.forEach(scoreline => {
        // Count occurrences for most frequent scoreline
        scoreCount[scoreline] = (scoreCount[scoreline] || 0) + 1;
        if (scoreCount[scoreline] > maxCount) {
            maxCount = scoreCount[scoreline];
            mostFrequentScoreline = scoreline;
        }

        // Calculate goal difference and total goals
        const [team1Score, team2Score] = scoreline.split('-').map(Number);
        const goalDifference = Math.abs(team1Score - team2Score);
        const totalGoals = team1Score + team2Score;

        // Update highest goal difference scoreline
        if (
            goalDifference > maxGoalDifference ||
            (goalDifference === maxGoalDifference && totalGoals > maxTotalGoals)
        ) {
            maxGoalDifference = goalDifference;
            maxTotalGoals = totalGoals;
            highestDiffScoreline = scoreline;
        }
    });

    return {
        mostFrequentScoreline,
        highestDiffScoreline
    };
};

export const simulateChampionsLeagueFixtures = async (numSimulations, formattedTeams, round, knockoutFixtures) => {
    let updatedTeams = JSON.parse(JSON.stringify(formattedTeams));

    const getUpdatedTeam = (teamName) => {
        return updatedTeams.find(team => team.displayName === teamName);
    }

    const replaceTeamObject = (teamArray, newTeamObject) => {
        return teamArray.map(team =>
            team.displayName === newTeamObject.displayName
                ? { ...newTeamObject, isUpdated: true }
                : team
        );
    };

    const updateTeamObject = async (teamName) => {
        //console.log(`Getting team object for ${teamName}`)
        const teamObject = { ...formattedTeams.find(team => team.displayName === teamName) };
        const teamAfterInjuries = await manageInjuriesInTeam(teamObject);
        const bestFormation = teamAfterInjuries.bestFormation
        teamObject.positionsData = convertPositionNames(bestFormation.positionNames, bestFormation.playerPositions, teamObject.formation);
        teamObject.bench = teamAfterInjuries.bench;
        teamObject.reserves = teamAfterInjuries.reserves
        if (!Object.values(teamObject.positionsData).some(player => player.id === teamObject.captain.id)) {
            assignCaptain(teamObject);
        }
        return teamObject;
    };

    const updateTeamWithInjuries = async (teamName) => {
        const updatedTeamObject = await updateTeamObject(teamName);
        updatedTeams = replaceTeamObject(updatedTeams, updatedTeamObject);
    };

    const fixtures = knockoutFixtures[round];
    let results = {};

    for (const fixture of fixtures) {
        const { team1, team2 } = fixture;
        results[`${team1.replaceAll(" ", "").trim()}-${team2.replaceAll(" ", "").trim()}`] = {
            team1Wins: 0, team2Wins: 0, percentage: 0.0,
            numExtraTime: 0, numPenaltyShootouts: 0,
            aggregateResults: []
        };
    }

    for (let i = 0; i < formattedTeams.length; i++) {
        const team = formattedTeams[i].displayName;
        await updateTeamWithInjuries(team);
    }

    for (let i = 0; i < numSimulations; i++) {
        for (const fixture of fixtures) {
            const team1Name = fixture.team1;
            const team2Name = fixture.team2

            const team1 = await getUpdatedTeam(team1Name);
            const team2 = await getUpdatedTeam(team2Name);

            // First leg
            const firstLegResult = await simulateMatch(team1, team2, team1, false);
            const team1GoalsFirstLeg = firstLegResult.team1Goals;
            const team2GoalsFirstLeg = firstLegResult.team2Goals;

            // Second leg
            const secondLegResult = await simulateMatch(
                team2, team1, team2, true,
                team1GoalsFirstLeg, team2GoalsFirstLeg
            );
            const team1GoalsSecondLeg = secondLegResult.team2Goals;
            const team2GoalsSecondLeg = secondLegResult.team1Goals;

            //Aggregate
            const team1Aggregate = team1GoalsSecondLeg;
            const team2Aggregate = team2GoalsSecondLeg;

            // const events = getMatchDetailsByMinute(secondLegResult);
            // const hasExtraTime = events.events.some(event => parseMinute(event.minute) >= 91);

            const fixtureKey = `${team1Name.replaceAll(" ", "").trim()}-${team2Name.replaceAll(" ", "").trim()}`;
            results[fixtureKey].aggregateResults.push(`${team1Aggregate}-${team2Aggregate}`);
            
            const { mostFrequentScoreline, highestDiffScoreline } = getScorelineStats(results[fixtureKey].aggregateResults);
            results[fixtureKey].mostFrequentScoreline = mostFrequentScoreline;
            results[fixtureKey].highestDiffScoreline = highestDiffScoreline;

            // if (hasExtraTime) {
            //     results[fixtureKey].numExtraTime++;
            // }

            if (team1Aggregate > team2Aggregate) {
                results[fixtureKey].team1Wins++;
            } else if (team2Aggregate > team1Aggregate) {
                results[fixtureKey].team2Wins++;
            } else {
                results[fixtureKey].numPenaltyShootouts++;
                const { team1Score, team2Score } = secondLegResult.penalties;
                if (team1Score > team2Score) {
                    results[fixtureKey].team2Wins++;
                } else {
                    results[fixtureKey].team1Wins++;
                }
            }
        }
    }

    for (const teams in results) {
        const fixture = results[teams];
        fixture.percentage = `${((fixture.team1Wins / numSimulations * 100)).toFixed(2)}%-${((fixture.team2Wins / numSimulations) * 100).toFixed(2)}%`;
    }

    return results;
};