export const championsLeagueKnockoutFixtures = {
    playoffs: [
        {
            team1: "Juventus",
            team2: "PSV",
            firstLegScore: "2-1",
            secondLegScore: "1-3 (a.e.t.)",
            aggregate: "3-4"
        },
        {
            team1: "Manchester City",
            team2: "Real Madrid",
            firstLegScore: "2-3",
            secondLegScore: "1-3",
            aggregate: "3-6"
        },
        {
            team1: "Brest",
            team2: "Paris Saint Germain",
            firstLegScore: "0-3",
            secondLegScore: "0-7",
            aggregate: "0-10"
        },
        {
            team1: "Club Brugge",
            team2: "Atalanta",
            firstLegScore: "2-1",
            secondLegScore: "3-1",
            aggregate: "5-2"
        },
        {
            team1: "AS Monaco",
            team2: "Benfica",
            firstLegScore: "0-1",
            secondLegScore: "3-3",
            aggregate: "3-4"
        },
        {
            team1: "Sporting CP",
            team2: "Borussia Dortmund",
            firstLegScore: "0-3",
            secondLegScore: "0-0",
            aggregate: "0-3"
        },
        {
            team1: "Celtic",
            team2: "FC Bayern München",
            firstLegScore: "1-2",
            secondLegScore: "1-1",
            aggregate: "2-3"
        },
        {
            team1: "Feyenoord",
            team2: "AC Milan",
            firstLegScore: "1-0",
            secondLegScore: "1-1",
            aggregate: "2-1"
        }
    ],
    '16': [
        {
            team1: "PSV",
            team2: "Arsenal",
            firstLegScore: "1-7",
            secondLegScore: "2-2",
            aggregate: "3-9"
        },
        {
            team1: "Real Madrid",
            team2: "Atlético Madrid",
            firstLegScore: "2-1",
            secondLegScore: "0-1",
            aggregate: "2-2 p(4-2)"
        },
        {
            team1: "Paris Saint Germain",
            team2: "Liverpool",
            firstLegScore: "0-1",
            secondLegScore: "1-0",
            aggregate: "1-1 p(4-1)"
        },
        {
            team1: "Club Brugge",
            team2: "Aston Villa",
            firstLegScore: "1-3",
            secondLegScore: "0-3",
            aggregate: "1-6"
        },
        {
            team1: "Benfica",
            team2: "Barcelona FC",
            firstLegScore: "0-1",
            secondLegScore: "1-3",
            aggregate: "1-4"
        },
        {
            team1: "Borussia Dortmund",
            team2: "Lille",
            firstLegScore: "1-1",
            secondLegScore: "2-1",
            aggregate: "3-2"
        },
        {
            team1: "FC Bayern München",
            team2: "Bayer 04 Leverkusen",
            firstLegScore: "3-0",
            secondLegScore: "2-0",
            aggregate: "5-0"
        },
        {
            team1: "Feyenoord",
            team2: "Inter Milan",
            firstLegScore: "0-2",
            secondLegScore: "1-2",
            aggregate: "1-4"
        }
    ],
    '8': [
        {
            team1: "Arsenal",
            team2: "Real Madrid",
        },
        {
            team1: "Paris Saint Germain",
            team2: "Aston Villa",
        },
        {
            team1: "Barcelona FC",
            team2: "Borussia Dortmund",
        },
        {
            team1: "FC Bayern München",
            team2: "Inter Milan",
        },
    ]
};

export const updateCLFixtures = (clKnockoutFixtures, results, round) => {
    const updatedFixtures = clKnockoutFixtures[round].map(fixture => {
        const fixtureKey = `${fixture.team1.replace(/\s+/g, '')}-${fixture.team2.replace(/\s+/g, '')}`;

        if (results[fixtureKey]) {
            return {
                ...fixture,
                firstLegScore: results[fixtureKey].mostFrequentScoreline,
                secondLegScore: results[fixtureKey].highestDiffScoreline,
                aggregate: results[fixture].percentage
            }
        }

        return fixture;
    });

    return updatedFixtures;
};