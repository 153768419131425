import './loading-popup.css';
import Ball from './resources/soccerball.png';
import Goal from './resources/goal.png';

const LoadingPopup = ({ displayText }) => {
    return (
        <div className="loading-popup">
            <div className="loading-content">
                <p className="loading-text">{displayText}</p>
                <div className="ball-goal-container">
                    <img src={Ball} alt="soccer ball" className="loading-ball" />
                    <img src={Goal} alt="goal" className="loading-goal" />
                </div>
            </div>
        </div>
    );
};

export default LoadingPopup;