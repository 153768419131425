import React, { useState, useEffect, useRef } from "react";
import './cl-knockout-bracket.css';
import { championsLeagueKnockoutFixtures } from "./ChampionsLeagueKnockouts";
import { simulateChampionsLeagueFixtures } from "./KnockoutSimulation";

const CLKnockoutBracket = ({ logos, knockoutResults }) => {
    const [matchHeight, setMatchHeight] = useState(0);
    const matchRef = useRef(null); // Reference for measuring match height

    useEffect(() => {
        if (matchRef.current) {
            setMatchHeight(matchRef.current.clientHeight);
        }
    }, []);

    const getTeamLogo = (teamName) => {
        const logo = logos.find((logo) => logo.club === teamName);
        return logo ? logo.logo : '';
    };

    const getRoundName = (round) => {
        if (round === '8') {
            return "Quarter Finals";
        } else if (round === '4') {
            return "Semi Finals";
        } else if (round === '2') {
            return "Final";
        } else {
            return `Round of ${round}`;
        }
    }

    const renderMatch = (fixture, roundIndex, matchIndex) => {
        const getShortenedName = (name) => {
            if (name === "Bayer 04 Leverkusen") {
                return "Bayer Leverkusen";
            }
            return name.replace("FC", "").trim();
        };

        const team1Logo = getTeamLogo(fixture.team1);
        const team2Logo = getTeamLogo(fixture.team2);

        const fixtureKey = `${fixture.team1.replaceAll(" ", "").trim()}-${fixture.team2.replaceAll(" ", "").trim()}`;

        // Hepler function to process scores
        const processScore = (score) => {
            if (!score) return ['', ''];
            const cleanScore = score.replace("(a.e.t.)", "").replace(/p\(\d+-\d+\)/, "").trim(); //Remove a.e.t. and p()
            let [team1Goals, team2Goals] = cleanScore.split("-").map(s => s.trim());

            return [team1Goals || '', team2Goals || ''];
        };

        const [firstLegTeam1, firstLegTeam2] = fixture.firstLegScore ? processScore(fixture.firstLegScore) : knockoutResults && knockoutResults[fixtureKey] ? processScore(knockoutResults[fixtureKey].mostFrequentScoreline) : '';
        const [secondLegTeam1, secondLegTeam2] = fixture.secondLegScore ? processScore(fixture.secondLegScore) : knockoutResults && knockoutResults[fixtureKey] ? processScore(knockoutResults[fixtureKey].highestDiffScoreline) : '';;

        let [aggregateTeam1, aggregateTeam2] = fixture.aggregate ? processScore(fixture.aggregate) : knockoutResults && knockoutResults[fixtureKey] ? processScore(knockoutResults[fixtureKey].percentage) : '';

        if (fixture.aggregate && fixture.aggregate.includes("p(")) {
            const penaltyMatch = fixture.aggregate.match(/p\((\d+)-(\d+)\)/);
            if (penaltyMatch) {
                aggregateTeam1 = `${aggregateTeam1} (${penaltyMatch[1]})`;
                aggregateTeam2 = `${aggregateTeam2} (${penaltyMatch[2]})`;
            }
        }

        return (
            <div key={`${roundIndex}-${matchIndex}`} className="knockout-match-grid">

                <div className="knockout-team-cell">
                    <img src={team1Logo} alt={`${fixture.team1} logo`} className="team-logo" />
                    <span>{getShortenedName(fixture.team1)}</span>
                </div>

                <div className="knockout-score">{firstLegTeam1}</div>
                <div className="knockout-score">{secondLegTeam1}</div>
                <div className="aggregate-score">{aggregateTeam1}</div>

                <div className="knockout-team-cell">
                    <img src={team2Logo} alt={`${fixture.team2} logo`} className="team-logo" />
                    <span>{getShortenedName(fixture.team2)}</span>
                </div>

                <div className="knockout-score">{firstLegTeam2}</div>
                <div className="knockout-score">{secondLegTeam2}</div>
                <div className="aggregate-score">{aggregateTeam2}</div>
            </div>
        );
    };

    const reversedRounds = Object.entries(championsLeagueKnockoutFixtures).reverse();

    return (
        <div className="cl-knockout-bracket">
            {/* {knockoutResults < 8 && <button onClick={() => simulateChampionsLeagueFixtures(500, formattedTeams, round, )}>Continue Simulation</button>} */}
            {reversedRounds.map(([roundKey, matches], roundIndex) => {
                const prevMatchesCount = reversedRounds[roundIndex - 1]?.[1]?.length || 0;
                const gapSize = (prevMatchesCount === matches.length || prevMatchesCount === 0) ? 10 : matchHeight; // Adjust gap based on previous round matches+
                return (
                    <div key={roundKey} className="cl-round" style={{ gap: `${gapSize}px` }}>
                        <h3 className="round-title">
                            {roundKey === "playoffs" ? "Playoffs" : getRoundName(roundKey)}
                        </h3>
                        {matches.map((match, matchIndex) => (
                            <div key={`${roundIndex}-${matchIndex}`} className="knockout-match-wrapper">
                                {matchIndex === 0 && roundIndex === 0 ? (
                                    <div ref={matchRef}>{renderMatch(match, roundIndex, matchIndex)}</div>
                                ) : (
                                    renderMatch(match, roundIndex, matchIndex)
                                )}
                            </div>
                        ))}
                    </div>
                );
            })}
        </div>
    );
};

export default CLKnockoutBracket;