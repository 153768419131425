import axios from "axios";
import { simulateMatch } from "./SimulateMatch";
import { backendUrl } from "../ItemTypes";
import { random } from "lodash";
import { generateFixtures } from "./LeagueSimulation";
import { championsLeagueJson } from "./ChampionsLeague/ChampionsLeagueFixtures";

const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // Swap
    }
    return array;
};

const generateCLFixtures = (groups) => {
    const fixtures = [];
    groups.forEach(group => {
        const groupFixture = generateFixtures(group.teams);
        const firstHalf = groupFixture.slice(0, groupFixture.length / 2)
        const secondHalf = firstHalf
            .slice() // Create a shallow copy to avoid mutating firstHalf
            .reverse() // Reverse the order of matchdays
            .map(matchday =>
                matchday.map(match => ({
                    home: match.away,
                    away: match.home,
                }))
            );

        const orderedFixtures = [...firstHalf, ...secondHalf];

        fixtures.push({
            name: group.name,
            fixtures: orderedFixtures,
        });
    });

    //console.log("Fixtures:", fixtures);

    return fixtures;
};

const generateGroups = (teams) => {
    //console.log('Current teams:', teams);

    const generateGroupNames = (numberOfGroups) => {
        const groupNames = [];
        const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

        for (let i = 0; i < numberOfGroups; i++) {
            groupNames.push(`Group ${alphabet[i]}`);
        }

        return groupNames;
    };

    const validateGroups = (groups) => {
        return groups.every(group => {
            const leagueIds = group.teams.map(team => team.leagueId);
            return new Set(leagueIds).size === leagueIds.length;
        });
    };

    const resolveConflicts = (groups) => {
        const groupSize = groups[0].teams.length;

        for (let groupA of groups) {
            const leagueCountA = {};
            groupA.teams.forEach(team => {
                leagueCountA[team.leagueId] = (leagueCountA[team.leagueId] || 0) + 1;
            });

            // Identify duplicate league IDs in the group
            const duplicateLeagueIds = Object.keys(leagueCountA).filter(
                leagueId => leagueCountA[leagueId] > 1
            );

            for (let leagueId of duplicateLeagueIds) {
                const conflictingTeam = groupA.teams.find(team => team.leagueId === parseInt(leagueId));

                for (let groupB of groups) {
                    if (groupA === groupB) continue;

                    // Find a team in groupB that doesn't conflict in groupA
                    const swapCandidate = groupB.teams.find(team =>
                        !groupA.teams.some(t => t.leagueId === team.leagueId) &&
                        !groupB.teams.some(t => t.leagueId === conflictingTeam.leagueId)
                    );

                    if (swapCandidate) {
                        // Perform the swap
                        const indexA = groupA.teams.indexOf(conflictingTeam);
                        const indexB = groupB.teams.indexOf(swapCandidate);
                        [groupA.teams[indexA], groupB.teams[indexB]] = [groupB.teams[indexB], groupA.teams[indexA]];
                        break;
                    }
                }
            }
        }

        return validateGroups;
    }

    const pots = {};

    teams.forEach(team => {
        if (!pots[team.potNumber]) pots[team.potNumber] = [];
        pots[team.potNumber].push(team.displayName);
    });

    //console.log("Pots:", structuredClone(pots));

    //Shuffle Pots:
    for (let pot = 1; pot <= 4; pot++) {
        pots[pot] = shuffleArray(pots[pot]);
    }
    const groupNames = generateGroupNames(teams.length / 4);
    //console.log('Group names:', groupNames);
    const groups = Array.from({ length: groupNames.length }, (_, index) => ({
        name: groupNames[index],
        teams: [],
    }));

    for (let groupIndex = 0; groupIndex < groups.length; groupIndex++) {
        for (let pot = 1; pot <= 4; pot++) {
            const teamName = pots[pot].pop();
            const teamObject = teams.filter(t => t.displayName === teamName)[0];
            groups[groupIndex].teams.push(teamObject);
        }
    }

    if (!validateGroups(groups)) {
        const success = resolveConflicts(groups);
        if (!success) {
            throw new Error("Unable to resolve conflicts under the given constraints.");
        }
    }

    return groups;
};

/**
 * Extracts matches from Champions League JSON into {home, away} format.
 *
 * @param {Object} championsLeagueJSON - The input JSON structure with team fixtures.
 * @returns {Array} - An array of match objects in the format {home, away}.
 */
const extractChampionsLeagueMatches = (championsLeagueJSON) => {
    const matches = [];

    const formatTeamName = (teamName) => teamName.replace(/\s+/g, ''); // Remove spaces from team names

    Object.keys(championsLeagueJSON).forEach(team => {
        const formattedTeam = formatTeamName(team);

        Object.keys(championsLeagueJSON[team]).forEach(pot => {
            const homeOpponent = formatTeamName(championsLeagueJSON[team][pot]["home"]);
            const awayOpponent = formatTeamName(championsLeagueJSON[team][pot]["away"]);

            const homeMatch = { home: formattedTeam, away: homeOpponent };
            const awayMatch = { home: awayOpponent, away: formattedTeam };

            // Avoid duplicate matches
            if (!matches.some(m => (m.home === homeMatch.home && m.away === homeMatch.away) ||
                (m.home === homeMatch.away && m.away === homeMatch.home))) {
                matches.push(homeMatch);
            }

            if (!matches.some(m => (m.home === awayMatch.home && m.away === awayMatch.away) ||
                (m.home === awayMatch.away && m.away === awayMatch.home))) {
                matches.push(awayMatch);
            }
        });
    });

    return matches;
};

const swapUnallocatedMatches = (unallocatedMatches, splitFixtures) => {
    /**
     * Shifts unallocated matches across same-pot keys in a circular fashion.
     * - Ensures pot1-pot1 moves to pot4-pot4, pot2-pot2 moves to pot1-pot1, etc.
     * - Places shifted matches in their respective new keys in splitFixtures.
     *
     * @param {Object} unallocatedMatches - Unallocated matches grouped by same-pot keys.
     * @param {Object} splitFixtures - The main fixture collection to update.
     */

    const samePotSwapMap = {
        "pot1-pot1": "pot2-pot3",
        "pot2-pot2": "pot3-pot4",
        "pot3-pot3": "pot1-pot4",
        "pot4-pot4": "pot1-pot2"
    };

    for (const samePotKey in unallocatedMatches) {
        if (!unallocatedMatches[samePotKey] || unallocatedMatches[samePotKey].length === 0) continue;

        const unallocatedMatch = unallocatedMatches[samePotKey][0]; // Take first unallocated match
        const swapPotKey = samePotSwapMap[samePotKey]; // Find a pot key to swap with

        if (!splitFixtures[swapPotKey] || splitFixtures[swapPotKey][0].length === 0) continue;

        // Select a random match to swap from index 0 of swapPotKey
        const swapIndex = Math.floor(Math.random() * splitFixtures[swapPotKey][0].length);
        const matchToSwap = splitFixtures[swapPotKey][0][swapIndex];

        // Perform the swap
        splitFixtures[swapPotKey][0][swapIndex] = unallocatedMatch; // Insert unallocated match
        splitFixtures[samePotKey][0].unshift(matchToSwap); // Move swapped match into same-pot key

        //console.log(`Swapped ${JSON.stringify(matchToSwap)} with ${JSON.stringify(unallocatedMatch)} between ${samePotKey} and ${swapPotKey}`);
    }

    return splitFixtures;
};

const splitPotFixtures = (organizedFixtures) => {
    /**
     * Splits each pot key's fixtures into two groups while ensuring no team plays twice in a section.
     *
     * @param {Object} organizedFixtures - The object containing fixtures grouped by pot keys.
     * @returns {Object} - An object where each pot key has two sections with unique teams per section.
     */

    const splitMatches = (matches) => {
        const section1 = [];
        const section2 = [];
        const section1Teams = new Set();
        const section2Teams = new Set();
        const unallocated = [];

        matches.forEach(match => {
            const { home, away } = match;
            if (!section1Teams.has(home) && !section1Teams.has(away)) {
                section1.push(match);
                section1Teams.add(home);
                section1Teams.add(away);
            } else if (!section2Teams.has(home) && !section2Teams.has(away)) {
                section2.push(match);
                section2Teams.add(home);
                section2Teams.add(away);
            } else {
                unallocated.push(match); // Couldn't be placed
            }
        });

        return { section1, section2, unallocated };
    };

    let splitFixtures = {};
    let unallocatedMatches = { samePot1: [], samePot2: [] };

    const samePotKeys = ["pot1-pot1", "pot2-pot2", "pot3-pot3", "pot4-pot4"];
    //Special handling for same-pot keys (combine two same-pot fixtures)

    // Step 1: Process all pots normally first
    for (const potKey in organizedFixtures) {
        if (!samePotKeys.includes(potKey)) {
            const matches = [...organizedFixtures[potKey]];
            const { section1, section2 } = splitMatches(matches);
            splitFixtures[potKey] = [section1, section2];
        }
    }

    for (const samePotKey of samePotKeys) {
        const matches = [...organizedFixtures[samePotKey]];
        const { section1, section2, unallocated } = splitMatches(matches);
        splitFixtures[samePotKey] = [section1, section2];
        unallocatedMatches[samePotKey] = unallocated;
    }

    console.log("Unallocated matches:", unallocatedMatches);
    // const allocated = swapAndReallocate(unallocatedMatches.samePot1, unallocatedMatches.samePot2);
    // console.log("Allocated matches:", allocated);

    const fullFixtures = swapUnallocatedMatches(unallocatedMatches, splitFixtures);

    return fullFixtures;
}

const assignPotMatchdays = (organizedFixtures) => {
    const matchdays = [];
    const remainingFixtures = JSON.parse(JSON.stringify(organizedFixtures)); // Deep copy to track remaining matches
    const teamMatchdayTracker = {}; // Tracks teams playing in a matchday

    // Predefined pot key cycles
    const potCycles = [
        ["pot1-pot2", "pot3-pot4"],
        ["pot1-pot3", "pot2-pot4"],
        ["pot1-pot4", "pot2-pot3"],
    ];

    const splitFixtures = splitPotFixtures(organizedFixtures);
    console.log("Split fixtures:", splitFixtures);

    return matchdays;
};

const organizeFixturesByPot = () => {
    const organizedFixtures = {};
    const seenMatches = new Set();

    Object.keys(championsLeagueJson).forEach(homePot => {
        Object.keys(championsLeagueJson[homePot]).forEach(homeTeam => {
            Object.keys(championsLeagueJson[homePot][homeTeam]).forEach(awayPot => {
                const homeOpponent = championsLeagueJson[homePot][homeTeam][awayPot]["home"];
                const awayOpponent = championsLeagueJson[homePot][homeTeam][awayPot]["away"];

                const formattedHomeTeam = homeTeam.replace(/\s+/g, '');
                const formattedHomeOpponent = homeOpponent.replace(/\s+/g, '');
                const formattedAwayOpponent = awayOpponent.replace(/\s+/g, '');
                const potKey = `${homePot}-${awayPot}`;

                // Create match identifiers
                const match1Key = [formattedHomeTeam, formattedHomeOpponent].sort().join('-');
                const match2Key = [formattedAwayOpponent, formattedHomeTeam].sort().join('-');

                // Initialize array for this pot combination if not exists
                if (!organizedFixtures[potKey]) {
                    organizedFixtures[potKey] = [];
                }

                // Avoid duplicates by ensuring the match hasn't been added before
                if (!seenMatches.has(match1Key)) {
                    organizedFixtures[potKey].push({
                        home: formattedHomeTeam,
                        away: formattedHomeOpponent,
                        pot: potKey
                    });
                    seenMatches.add(match1Key);
                }

                if (!seenMatches.has(match2Key)) {
                    organizedFixtures[potKey].push({
                        home: formattedAwayOpponent,
                        away: formattedHomeTeam,
                        pot: potKey
                    });
                    seenMatches.add(match2Key);
                }
            });

        });
    });

    // Remove empty pot keys
    Object.keys(organizedFixtures).forEach(potKey => {
        if (organizedFixtures[potKey].length === 0) {
            delete organizedFixtures[potKey];
        }
    });

    return organizedFixtures;
};

export const assignMatchdays = () => {
    // const teams = Object.keys(championsLeagueJson).map(team => team.replace(/\s+/g, ''));
    // const matches = extractChampionsLeagueMatches(championsLeagueJson);
    // const shuffledMatches = shuffleArray(matches);
    // console.log('All matches:', shuffledMatches);

    const fixturesByPot = organizeFixturesByPot();
    console.log("Fixtures by pot:", fixturesByPot);
    const matchdays = assignPotMatchdays(fixturesByPot);
    console.log("Matchdays:", matchdays);
};

const generateMatchKey = (home, away) => {
    return `${home}-${away}`;
}

export { generateCLFixtures, generateGroups };