import { calculateAverageRatings } from "./SimulateMatch";

export const calculateFormChange = async (match, homeTeam, awayTeam) => {
    const homeGoals = match.score.split('-')[0];
    const awayGoals = match.score.split('-')[1];
    const goalDiff = Math.abs(homeGoals - awayGoals);

    // Fetch team strength
    const homeStrength = await calculateAverageRatings(homeTeam.positionsData, homeTeam.formation);
    const awayStrength = await calculateAverageRatings(awayTeam.positionsData, awayTeam.formation);

    // Compare attacks & defenses separately
    const attackVsDefenseFactor = ((homeStrength.attack - awayStrength.defense) - (awayStrength.attack - homeStrength.defense)) * 0.2;
    const midfieldFactor = (homeStrength.midfield - awayStrength.midfield) * 0.15;

    // Calculate expectation factor (Only applies if rankings are at least 5 places apart)
    let expectationFactor = 0;
    const rankingDiff = homeTeam.lastSeasonRanking - awayTeam.lastSeasonRanking;

    if (Math.abs(rankingDiff) >= 5) {
        expectationFactor = rankingDiff / 10; // Normalized factor
    }

    let homeFormChange = 0;
    let awayFormChange = 0;

    if (match.homeOrAway === "Home") {
        if (match.result === "W") { // Home team wins
            homeFormChange = 3 + goalDiff * 0.5 + attackVsDefenseFactor + midfieldFactor - expectationFactor;
            awayFormChange = -3 - goalDiff * 0.5 - attackVsDefenseFactor - midfieldFactor + expectationFactor;
        } else if (match.result === "L") { // Away team wins
            homeFormChange = -3 - goalDiff * 0.5 + attackVsDefenseFactor + midfieldFactor + expectationFactor;
            awayFormChange = 3 + goalDiff * 0.5 - attackVsDefenseFactor - midfieldFactor - expectationFactor;
        } else { // Draw
            homeFormChange = 1 + midfieldFactor - expectationFactor;
            awayFormChange = 1 - midfieldFactor + expectationFactor;
        }
    } else { // Away team case
        if (match.result === "W") { // Away team wins
            awayFormChange = 3 + goalDiff * 0.5 + attackVsDefenseFactor + midfieldFactor - expectationFactor;
            homeFormChange = -3 - goalDiff * 0.5 - attackVsDefenseFactor - midfieldFactor + expectationFactor;
        } else if (match.result === "L") { // Home team wins
            awayFormChange = -3 - goalDiff * 0.5 + attackVsDefenseFactor + midfieldFactor + expectationFactor;
            homeFormChange = 3 + goalDiff * 0.5 - attackVsDefenseFactor - midfieldFactor - expectationFactor;
        } else { // Draw
            awayFormChange = 1 + midfieldFactor - expectationFactor;
            homeFormChange = 1 - midfieldFactor + expectationFactor;
        }
    }

    return { homeFormChange, awayFormChange };
};

export const updateFormFromHistory = async (teamFormHistory, formattedTeams) => {
    for (const team of formattedTeams) {
        const history = teamFormHistory[team.displayName];
        
        if (!history || history.length === 0) {
            continue;
        }

        let newForm = team.form?.value || 0.0; 
        let weight = 1.0;
        
        team.form.resultString += teamFormHistory[team.displayName][history.length - 1].result
        for (let i = history.length - 1; i >= 0; i--) {
            const match = history[i];
            const opponent = formattedTeams.find(t => t.displayName === match.opponentName);
            if (!opponent) {
                console.error(`${opponent} cannot be found!`);
                continue;
            }

            const { homeFormChange, awayFormChange } = await calculateFormChange(match, team, opponent);
            const matchWeight = weight * Math.max(1, 20 - match.opponentRanking) / 20; // Stronger teams give higher weight

            newForm += (match.homeOrAway === "Home" ? homeFormChange : awayFormChange) * matchWeight;
            weight *= 0.9; // Older matches have less weight
        }
        // Ensure form is within range
        const updatedFormValue = Math.max(-10, Math.min(10, parseFloat(newForm.toFixed(2))));

        // Initialize form object if not present
        if (!team.form) {
            team.form = { value: 0.0, resultString: "" };
        }

        // Update form value
        team.form.value = updatedFormValue;
    }
    return formattedTeams;
};

export const updateFormHistory = (teamFormHistory, result, formattedTeams) => {
    const homeTeam = formattedTeams.find(team => team.team_id === result.team1.team_id);
    const awayTeam = formattedTeams.find(team => team.team_id === result.team2.team_id);

    const homeGoals = result.homeGoals;
    const awayGoals = result.awayGoals;

    const homeResult = homeGoals > awayGoals ? "W" : homeGoals < awayGoals ? "L" : "D";
    const awayResult = awayGoals > homeGoals ? "W" : awayGoals < homeGoals ? "L" : "D";

    const matchInfoHome = {
        score: `${homeGoals}-${awayGoals}`,
        homeOrAway: "Home",
        opponentName: awayTeam.displayName,
        opponentRanking: awayTeam.lastSeasonRanking,
        result: homeResult
    };

    const matchInfoAway = {
        score: `${awayGoals}-${homeGoals}`,
        homeOrAway: "Away",
        opponentName: homeTeam.displayName,
        opponentRanking: homeTeam.lastSeasonRanking,
        result: awayResult
    };

    // Add match info to history (keep only the last 5 matches)
    if (!teamFormHistory[homeTeam.displayName]) teamFormHistory[homeTeam.displayName] = [];
    if (!teamFormHistory[awayTeam.displayName]) teamFormHistory[awayTeam.displayName] = [];

    teamFormHistory[homeTeam.displayName].push(matchInfoHome);
    teamFormHistory[awayTeam.displayName].push(matchInfoAway);

    if (teamFormHistory[homeTeam.displayName].length > 7) teamFormHistory[homeTeam.displayName].shift();
    if (teamFormHistory[awayTeam.displayName].length > 7) teamFormHistory[awayTeam.displayName].shift();
}