import React, { useRef, useState, useEffect } from 'react';
import './PlayerCardMini.css';
import PlayerStatsPopup from '../StatsPopup';
import { useDrag } from 'react-dnd';
import PlayerPhoto from './PlayerPhoto';
import { ItemTypes } from '../ItemTypes';
import PlayerStatsInput from './PlayerStatsInput';
import InjuryDisplay from './InjuryDisplay';

/**
     * @param {*player} player to be removed
     * @param {*function} onRemovePlayer prop to from App to handle removing player
     */
const PlayerCardMini = ({ player, removePlayer, index, position, handleSwitch, highlightedPosition,
    isQueue, saveAllChanges, getPlayerStats, setFunction, onPlayerDoubleClick, onStatsChange, pushToQueue, addByPosition }) => {
    const [statsPopupOpen, setStatsPopupOpen] = useState(false);
    const playerCardRef = useRef(null);
    const [lastTap, setLastTap] = useState(0);
    const [isMobile, setIsMobile] = useState(false);
    const [canSwitch, setCanSwitch] = useState(false);

    useEffect(() => {
        setIsMobile(/Mobi|Android/i.test(navigator.userAgent));
    }, []);

    useEffect(() => {
        if (
            highlightedPosition &&
            highlightedPosition.key !== '' &&
            highlightedPosition.key !== position
        ) {
            setCanSwitch(true);
        } else {
            setCanSwitch(false);
        }
    }, [highlightedPosition]);

    const combineRefs = (element) => {
        drag(element);
        playerCardRef.current = element;
    };

    useEffect(() => {
        // Step 2: Add event listener on mount
        const handleClickOutside = (event) => {
            if (playerCardRef.current && !playerCardRef.current.contains(event.target)) {
                handleCloseMenu();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);
        return () => {
            // Clean up the event listener when the component unmounts
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [playerCardRef]);

    const getPosition = () => {
        if (player.position && player.position.includes(";")) {
            const positions = player.position.split(";");
            return positions[0];
        }

        return player.position;
    }

    const [menuState, setMenuState] = useState({ isOpen: false, x: 0, y: 0 });
    const handleContextMenu = (e) => {
        e.preventDefault();
        const { clientX, clientY } = e;
        const newMenuState = { isOpen: true, x: clientX, y: clientY };
        setMenuState(newMenuState);
    };

    const handleCloseMenu = () => {
        setMenuState({ ...menuState, isOpen: false, x: 0, y: 0 });
    }

    const getLastName = (fullname) => {
        if (fullname.includes("José Pedro Malheiro")) {
            console.log("Full name has Jose Sa");
            return "José Sá";
        }
        const parts = fullname.split(' ');
        if (parts.length === 1 || parts[1].includes('Jr') || fullname.length < 7) {
            return fullname;
        }
        if (parts[1].length < 3) {
            return parts[1] + ' ' + parts[2];
        }
        parts.shift();
        return parts.join(' ');
    }

    //Toggles whether status window is shown
    const toggleStatsPopup = () => {
        setStatsPopupOpen(!statsPopupOpen);
    };

    const handleDoubleClick = () => {
        if (isQueue) onPlayerDoubleClick(player);
    }

    const handleTap = (e) => {
        e.preventDefault();
        const now = Date.now();
        const DOUBLE_TAP_DELAY = 300; // 300ms delay for double-tap detection

        if (now - lastTap < DOUBLE_TAP_DELAY) {
            handleDoubleClick(); // Trigger the double-click function if it's a double-tap
        } else {
            setLastTap(now);
        }
    };

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.PLAYER,
        item: {
            type: ItemTypes.PLAYER,
            currentPosition: player.currentPosition,
            name: player.name, player, index, source: player.type
        },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    const PlayerCardMenu = () => {
        return (
            <div onContextMenu={handleContextMenu}>
                {menuState.isOpen && (
                    <div className="custom-menu" style={{ top: `${menuState.y}px`, left: `${menuState.x}px` }}>
                        <div
                            className="menu-item"
                            onClick={() => {
                                if (isQueue) {
                                    removePlayer(setFunction, index);
                                } else {
                                    removePlayer(position);
                                }
                                handleCloseMenu();
                            }}
                            onTouchEnd={() => {
                                if (isQueue) {
                                    removePlayer(setFunction, index);
                                } else {
                                    removePlayer(position);
                                }
                                handleCloseMenu();
                            }}
                        >
                            Remove Player
                        </div>
                        {isQueue && player.type !== 'queue' && (
                            <div
                                className="menu-item"
                                onClick={() => pushToQueue(player, 'queue')}
                                onTouchEnd={() => pushToQueue(player, 'queue')}
                            >
                                Move To Queue
                            </div>
                        )}
                        {isQueue && player.type === 'queue' && (
                            <>
                                <div
                                    className="menu-item"
                                    onClick={() => pushToQueue(player, 'bench')}
                                    onTouchEnd={() => pushToQueue(player, 'bench')}
                                >
                                    Move To Bench
                                </div>
                                <div
                                    className="menu-item"
                                    onClick={() => pushToQueue(player, 'reserves')}
                                    onTouchEnd={() => pushToQueue(player, 'reserves')}
                                >
                                    Move To Reserves
                                </div>
                            </>
                        )}
                        {isQueue && (
                            <div
                                className="menu-item"
                                onClick={() => addByPosition(player)}
                                onTouchEnd={() => addByPosition(player)}
                            >
                                Add To Formation
                            </div>
                        )}
                        {!isQueue && (
                            <div
                                className="menu-item"
                                onClick={canSwitch ? handleSwitch : null}
                                onTouchEnd={canSwitch ? handleSwitch : null}
                                style={{
                                    opacity: canSwitch ? 1 : 0.5,
                                    cursor: canSwitch ? 'pointer' : 'not-allowed'  // Set cursor style based on canSwitch
                                }}
                            >
                                Switch Player
                            </div>
                        )}
                        <div
                            className="menu-item"
                            onClick={toggleStatsPopup}
                            onTouchEnd={toggleStatsPopup}
                        >
                            Show Stats Menu
                        </div>
                        <div
                            className="menu-item"
                            onClick={handleCloseMenu}
                            onTouchEnd={handleCloseMenu}
                        >
                            Exit
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="player-card-mini-container">
            {isQueue && (<PlayerStatsInput player={player} onStatsChange={onStatsChange} />)}
            <div onDoubleClick={() => handleDoubleClick(player)}
                className="player-card-mini"
                onTouchEnd={handleTap}
                onContextMenu={handleContextMenu}
                ref={combineRefs}
                style={{
                    opacity: isDragging ? 0.5 : 1,
                    cursor: 'grab',
                }}
            >
                {/* Injury Display at the Top-Right Corner */}
                {player.injuryStatus && player.injuryStatus.type && (
                    <div className="injury-top-right">
                        <InjuryDisplay player={player} />
                    </div>
                )}

                <div className="left-panel">
                    <div className="player-info">
                        <h2 style={{ fontSize: '20px' }}>{player.overall}</h2>
                        <h3 style={{ fontSize: getPosition().length > 2 ? '12px' : '16px' }}>
                            <span dangerouslySetInnerHTML={{ __html: getPosition() }} />
                        </h3>
                        <img src={player.clubLogo} alt="Club Logo" className="club-logo" />
                        <img src={player.flag} alt="Country Flag" className="country-flag" />
                    </div>
                </div>
                <div className="right-panel">
                    <PlayerPhoto player={player} className="player-photo" />
                    <p style={{ marginBottom: '10px', fontSize: '14px' }}>{getLastName(player.name)}</p>
                </div>
                {isMobile && isQueue && !menuState.isOpen && (
                    <div className="mobile-menu-button" onTouchEnd={handleContextMenu}>
                        ⋮
                    </div>
                )}
                <div id="player-card-menu-portal"></div>
                {menuState.isOpen && <PlayerCardMenu />}
                {statsPopupOpen && <PlayerStatsPopup
                    player={player}
                    saveAllChanges={saveAllChanges}
                    onClose={toggleStatsPopup}
                    getPlayerStats={getPlayerStats} />}
            </div>
        </div>
    );
}

export default PlayerCardMini;