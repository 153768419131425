import React, { useState, useEffect } from "react";
import { simulateLeague } from "./LeagueSimulation";
import axios from 'axios';
import './league-simulator.css';
import PremierLeague from './Premier_League_Icons/premier_league.png';
import LaLiga from './Premier_League_Icons/la_liga.png';
import ChampionsLeague from './Premier_League_Icons/champions_league.png';
import LigueUn from './Premier_League_Icons/ligue_un.png';
import SerieA from './Premier_League_Icons/serie_a.png';
import Bundesliga from './Premier_League_Icons/bundesliga.png';
import { fetchLogos, fetchUserIp } from "../SearchBar";
import Ball from '../resources/soccerball.png';
import Assist from '../resources/assist.png';
import RedCard from '../resources/red_card.png';
import YellowCard from '../resources/yellow_card.png';
import YellowAndRed from '../resources/Yellow-red_card.svg.png';
import InjuredSymbol from '../resources/injury_symbol.png';
import MissedPenalty from '../resources/missed_penalty.png';
import Captain from '../resources/captain.png';
import motmAward from '../resources/man_of_the_match.png';
import In from '../resources/in.png';
import Out from '../resources/out.png';
import PlayerPhoto from "../PlayerCardComponents/PlayerPhoto";
import PlayerStatsMenu from "./PlayerStatsMenu";
import { parseMinute } from "./MatchResultPopup";
import LeagueStatistics from "./LeagueStatistics";
import { backendUrl } from "../ItemTypes";
import { getData } from "../Utility/DatabaseStorage";
import ChampionsLeagueSimulator from "./ChampionsLeagueSimulator";
import KnockoutResult from "./KnockoutResult";
import LoadingPopup from "../LoadingPopup";
import CLKnockoutBracket from "./ChampionsLeague/CLKnockoutBracket";
import { simulateChampionsLeagueFixtures } from "./ChampionsLeague/KnockoutSimulation";
import { championsLeagueKnockoutFixtures, updateCLFixtures } from "./ChampionsLeague/ChampionsLeagueKnockouts";

const leagues = [
    { id: 13, name: "Premier League", icon: PremierLeague },
    { id: 53, name: "La Liga", icon: LaLiga },
    { id: 19, name: "Bundesliga", icon: Bundesliga },
    { id: 16, name: "Ligue 1", icon: LigueUn },
    { id: 31, name: "Serie A", icon: SerieA },
    { id: 99, name: "Champions League", icon: ChampionsLeague },
    { id: 69, name: "Champions League Knockout", icon: ChampionsLeague }
];

export const getFullName = (name, fullName, nationality) => {
    if (!name || !fullName || !nationality) {
        return name || '';
    }

    if (name.includes('Jr.')) {
        return name;
    }

    if (nationality === 'Japan' || nationality === 'Korea Republic' || nationality === 'China') {
        return name;
    }

    let firstName, lastName;
    let longNameParts = fullName.split(' ');

    let lastNameFromShort;
    if (name.includes('.')) {
        firstName = fullName.split(' ')[0];
        //console.log(`Player name: ${name}, fullName: ${fullName}`);
        const lastNameParts = name.split('. ')[1].split(' ');
        if (lastNameParts.length >= 2) {
            lastNameFromShort = lastNameParts.join('-');
        } else {
            lastNameFromShort = lastNameParts[0]; // Fallback in case there aren't two words
        }
    } else if (name.includes(' ')) {
        firstName = name.split(' ')[0];
        lastNameFromShort = name.split(' ')[1];
    } else {
        firstName = name;
    }

    if (!name.includes(' ')) {
        if (name === "Carvajal") {
            firstName = "Daniel";
            lastName = name;
        } else {
            lastName = ''
        }
    } else if (!fullName.endsWith(lastNameFromShort)) {
        lastName = lastNameFromShort;
    } else {
        lastName = longNameParts[longNameParts.length - 1];
    }

    lastName = lastName.replace('-', ' ');

    const returnName = `${firstName} ${lastName}`;

    //console.log(`${name} full Name: ${returnName}`);
    return returnName;
}

const getTeamStatistics = (leagueTable, teamName, matches) => {
    let longestWinningStreak = { startDate: '', endDate: '', length: 0 };
    let longestUnbeatenStreak = { startDate: '', endDate: '', length: 0 };
    let longestWinlessStreak = { startDate: '', endDate: '', length: 0 };
    let longestLosingStreak = { startDate: '', endDate: '', length: 0 };

    let currentWinningStreak = { startDate: '', length: 0 };
    let currentUnbeatenStreak = { startDate: '', length: 0 };
    let currentWinlessStreak = { startDate: '', length: 0 };
    let currentLosingStreak = { startDate: '', length: 0 };

    let largestHomeVictory = [];
    let largestAwayVictory = [];
    let largestHomeLoss = [];
    let largestAwayLoss = [];
    let teamPosition = -1;

    //Finds league position in simulation
    if (leagueTable[0].groupName) {
        leagueTable.forEach(group => {
            group.leagueTable.forEach((team, index) => {
                if (team.displayName === teamName) {
                    teamPosition = index + 1; // Position in the group league table
                }
            });
        });
    } else {
        leagueTable.forEach((team, index) => {
            if ((team.displayName === teamName)) {
                teamPosition = index + 1;
            }
        });
    }

    //console.log(`${teamName} matches:`, matches);

    matches.forEach((match, index) => {
        const isHome = match.home === teamName;
        const goalsFor = isHome ? match.homeGoals : match.awayGoals;
        const goalsAgainst = isHome ? match.awayGoals : match.homeGoals;

        // console.log(`${teamName} goals for: ${goalsFor}, goalsAgainst: ${goalsAgainst}`);
        const goalDifference = goalsFor - goalsAgainst;
        const scoreline = `${goalsFor} - ${goalsAgainst}`
        const date = match.date;

        //Calculates longest winning streak
        if (goalsFor > goalsAgainst) {
            if (currentWinningStreak.length === 0) {
                currentWinningStreak.startDate = match.date;
            }

            currentWinningStreak.length++;
            currentUnbeatenStreak.length++;

            if (currentWinlessStreak.length > longestWinlessStreak.length) {
                longestWinlessStreak = {
                    length: currentWinlessStreak.length,
                    startDate: currentWinlessStreak.startDate,
                    endDate: matches[index - 1]?.date || match.date
                }
            }

            //Reset losing streak
            currentWinlessStreak = { startDate: '', length: 0 };
            currentLosingStreak = { startDate: '', length: 0 };

            if (currentUnbeatenStreak.startDate === '') {
                currentUnbeatenStreak.startDate = match.date;
            }
        } else if (goalsFor === goalsAgainst) {
            currentUnbeatenStreak.length++;
            if (currentUnbeatenStreak.startDate === '') {
                currentUnbeatenStreak.startDate = match.date;
            }

            if (currentWinlessStreak.startDate === '') {
                currentWinlessStreak.startDate = match.date;
            }
            currentWinlessStreak.length++;

            // Reset losing streak on a draw
            if (currentLosingStreak.length > longestLosingStreak.length) {
                longestLosingStreak = {
                    length: currentLosingStreak.length,
                    startDate: currentLosingStreak.startDate,
                    endDate: matches[index - 1]?.date || match.date
                };
            }
            currentLosingStreak = { startDate: '', length: 0 };

            if (currentWinningStreak.length > longestWinningStreak.length) {
                longestWinningStreak = {
                    length: currentWinningStreak.length,
                    startDate: currentWinningStreak.startDate,
                    endDate: matches[index - 1]?.date || match.date
                }
            }

            currentWinningStreak = { length: 0, startDate: '' };
        } else {
            if (currentWinlessStreak.startDate === '') {
                currentWinlessStreak.startDate = match.date;
            }
            currentWinlessStreak.length++;

            if (currentLosingStreak.startDate === '') {
                currentLosingStreak.startDate = match.date;
            }
            currentLosingStreak.length++;

            if (currentUnbeatenStreak.length > longestUnbeatenStreak.length) {
                longestUnbeatenStreak = {
                    length: currentUnbeatenStreak.length,
                    startDate: currentUnbeatenStreak.startDate,
                    endDate: matches[index - 1]?.date || match.date
                };
            }

            currentUnbeatenStreak = { length: 0, startDate: '' };

            if (currentWinningStreak.length > longestWinningStreak.length) {
                longestWinningStreak = {
                    length: currentWinningStreak.length,
                    startDate: currentWinningStreak.startDate,
                    endDate: matches[index - 1]?.date || match.date
                };
            }

            currentWinningStreak = { length: 0, startDate: '' };
        }

        //Largest home win
        if (isHome && goalDifference > 0) {
            if (largestHomeVictory.length === 0 || goalDifference > largestHomeVictory[0].goalDifference) {
                // If a new larger goal difference is found, reset the array
                largestHomeVictory = [{
                    opponent: match.away,
                    scoreline,
                    goalDifference,
                    date
                }];
            } else if (goalDifference === largestHomeVictory[0].goalDifference) {
                // If the goal difference is the same, add to the array
                largestHomeVictory.push({
                    opponent: match.away,
                    scoreline,
                    goalDifference,
                    date
                });
            }
        }

        // Largest away win
        if (!isHome && goalDifference > 0) {
            if (largestAwayVictory.length === 0 || goalDifference > largestAwayVictory[0].goalDifference) {
                largestAwayVictory = [{
                    opponent: match.home,
                    scoreline,
                    goalDifference,
                    date
                }];
            } else if (goalDifference === largestAwayVictory[0].goalDifference) {
                largestAwayVictory.push({
                    opponent: match.home,
                    scoreline,
                    goalDifference,
                    date
                });
            }
        }

        // Update largest home loss
        if (isHome && goalDifference < 0) {
            if (largestHomeLoss.length === 0 || goalDifference < largestHomeLoss[0].goalDifference) {
                largestHomeLoss = [{
                    opponent: match.away,
                    scoreline,
                    goalDifference,
                    date
                }];
            } else if (goalDifference === largestHomeLoss[0].goalDifference) {
                largestHomeLoss.push({
                    opponent: match.away,
                    scoreline,
                    goalDifference,
                    date
                });
            }
        }

        if (!isHome && goalDifference < 0) {
            if (largestAwayLoss.length === 0 || goalDifference < largestAwayLoss[0].goalDifference) {
                largestAwayLoss = [{
                    opponent: match.home,
                    scoreline,
                    goalDifference,
                    date
                }];
            } else if (goalDifference === largestAwayLoss[0].goalDifference) {
                largestAwayLoss.push({
                    opponent: match.home,
                    scoreline,
                    goalDifference,
                    date
                });
            }
        }
    });

    if (currentWinningStreak.length > longestWinningStreak.length) {
        longestWinningStreak = {
            length: currentWinningStreak.length,
            startDate: currentWinningStreak.startDate,
            endDate: matches[matches.length - 1].date
        };
    }

    if (currentUnbeatenStreak.length > longestUnbeatenStreak.length) {
        longestUnbeatenStreak = {
            length: currentUnbeatenStreak.length,
            startDate: currentUnbeatenStreak.startDate,
            endDate: matches[matches.length - 1].date
        };
    }

    if (currentWinlessStreak.length > longestWinlessStreak.length) {
        longestWinlessStreak = {
            length: currentWinlessStreak.length,
            startDate: currentWinlessStreak.startDate,
            endDate: matches[matches.length - 1].date
        };
    }

    if (currentLosingStreak.length > longestLosingStreak.length) {
        longestLosingStreak = {
            length: currentLosingStreak.length,
            startDate: currentLosingStreak.startDate,
            endDate: matches[matches.length - 1].date
        };
    }

    let topScorers = {};

    // Calculate the total goals for each player
    matches.forEach(match => {
        const goals = match.events.filter(event => event.type === 'goal' && event.team === teamName);
        if (goals.length > 0) {
            goals.forEach(goal => {
                if (!goal.name.includes('o.g.')) {
                    if (!topScorers[goal.name]) {
                        topScorers[goal.name] = 0;
                    }
                    topScorers[goal.name]++;
                }
            });
        }
    });

    const sortedScorers = Object.entries(topScorers).sort(([, a], [, b]) => b - a);
    let groupedScorers = {};
    let uniqueGoalTotals = new Set(); // To track unique goal totals

    for (let [player, goals] of sortedScorers) {
        if (uniqueGoalTotals.size < 5 || uniqueGoalTotals.has(goals)) {
            // If the goal count is already in the set or we haven't hit 5 unique totals yet
            if (!groupedScorers[goals]) {
                groupedScorers[goals] = [];
            }
            groupedScorers[goals].push(player); // Group players with the same number of goals
            uniqueGoalTotals.add(goals);
        }
    }

    const stats = {
        team: teamName,
        position: teamPosition,
        largestHomeVictory,
        largestAwayVictory,
        largestHomeLoss,
        largestAwayLoss,
        longestWinningStreak,
        longestUnbeatenStreak,
        longestWinlessStreak,
        longestLosingStreak,
        top5Scorers: groupedScorers
    };

    return stats;
};

const renderEvents = (events, team, injuries) => {
    const parseMinute = (minute) => {
        const minuteStr = minute.toString().replace('(P)', '');

        if (minuteStr.includes('+')) {
            const [regular, stoppage] = minuteStr.split('+').map(Number);
            return regular + stoppage / 100;
        }
        return Number(minuteStr);
    };

    const goalsByPlayer = {};
    const redCards = [];
    const yellowCards = [];
    const yellowToReds = [];
    const missedPenalties = [];

    events.forEach(event => {
        if (event.team === team) {
            switch (event.type) {
                case 'goal':
                    if (!goalsByPlayer[event.name]) {
                        goalsByPlayer[event.name] = [];
                    }
                    goalsByPlayer[event.name].push(event.minute);
                    break;
                case 'redCard':
                    redCards.push(event);
                    break;
                case 'yellowCard':
                    yellowCards.push(event);
                    break;
                case 'yellowToRed':
                    yellowToReds.push(event);
                    break;
                case 'missedPenalty':
                    missedPenalties.push(event);
                    break;
                default:
                    break;
            }
        }
    });

    const combinedEvents = [];

    // Add goals to combinedEvents, sorted by the time of the first goal
    Object.keys(goalsByPlayer).forEach(player => {
        goalsByPlayer[player].sort((a, b) => parseMinute(a) - parseMinute(b)); // Sort player goals by time
        combinedEvents.push({
            type: 'goal',
            name: player,
            minute: parseMinute(goalsByPlayer[player][0]), // Use the first goal time for sorting
            originalMinutes: goalsByPlayer[player].map(minute => `${minute}'`).join(', ') // Collect all goal times
        });
    });

    let injuryElements = '';
    // Injuries are handled separately and placed at the bottom
    if (injuries.length > 0) {
        const uniqueInjuries = new Set();
        injuryElements = injuries
            .filter(player => player.team === team) // Filter by team membership
            .filter(player => { // Filter to only include unique players
                if (uniqueInjuries.has(player.name)) {
                    return false; // Skip if the player is already in the Set
                } else {
                    uniqueInjuries.add(player.name); // Add player to the Set
                    return true; // Include this player
                }
            })
            .map((player, index) => (
                <div key={`injury-${index}`} className="event">
                    <img src={InjuredSymbol} alt="injury" className="event-icon" /> {/* Injury icon */}
                    <span>{player.name}</span>
                </div>
            ));
    }


    redCards.forEach(event => {
        combinedEvents.push({
            type: 'redCard',
            name: event.name,
            minute: parseMinute(event.minute),
            originalMinute: event.minute
        });
    });

    yellowCards.forEach(event => {
        combinedEvents.push({
            type: 'yellowCard',
            name: event.name,
            minute: parseMinute(event.minute),
            originalMinute: event.minute
        });
    });

    yellowToReds.forEach(event => {
        combinedEvents.push({
            type: 'yellowToRed',
            name: event.name,
            minute: parseMinute(event.minute),
            originalMinute: event.minute
        });
    });

    missedPenalties.forEach(event => {
        combinedEvents.push({
            type: 'missedPenalty',
            name: event.name,
            minute: parseMinute(event.minute),
            originalMinute: event.minute
        });
    })

    combinedEvents.sort((a, b) => a.minute - b.minute);

    //All events
    const eventElements = combinedEvents.map((event, index) => {
        let icon, altText, eventText;

        switch (event.type) {
            case 'goal':
                icon = Ball;
                altText = 'goal';
                eventText = `${event.name} ${event.originalMinutes}`;
                break;
            case 'redCard':
                icon = RedCard;
                altText = 'red card';
                eventText = `${event.name} ${event.originalMinute}'`;
                break;
            case 'yellowCard':
                icon = YellowCard;
                altText = 'yellow card';
                eventText = `${event.name} ${event.originalMinute}'`;
                break;
            case 'yellowToRed':
                icon = YellowAndRed;
                altText = 'red after yellow';
                eventText = `${event.name} ${event.originalMinute}'`;
                break;
            case 'missedPenalty':
                icon = MissedPenalty;
                altText = 'missed penalty';
                eventText = `${event.name} ${event.originalMinute}'`;
                break;
            default:
                icon = '';
                console.log(`Error in ${event.type}`);
                altText = 'error';
                eventText = 'error';
                break;
        }

        return (
            <div key={`event-${index}`} className="event">
                <img src={icon} alt={altText} className="event-icon" />
                <span>{eventText}</span>
            </div>
        );
    });

    return (
        <div className="team-events">
            {eventElements}
            {injuryElements}
        </div>
    );
}

const Tabs = ({ activeTab, onChangeTab, hasKnockouts }) => {
    return (
        <div className="tabs">
            <button onClick={() => onChangeTab('fixtures')} className={activeTab === 'fixtures' ? 'active' : ''}>
                Fixtures
            </button>
            {hasKnockouts && (
                <button onClick={() => onChangeTab('knockout')} className={activeTab === 'knockout' ? 'active' : ''}>
                    Knockout Fixtures
                </button>
            )}
            <button onClick={() => onChangeTab('scorers')} className={activeTab === 'scorers' ? 'active' : ''}>
                Top Scorers
            </button>
            <button onClick={() => onChangeTab('assisters')} className={activeTab === 'assisters' ? 'active' : ''}>
                Top Assisters
            </button>
            <button onClick={() => onChangeTab('goalkeepers')} className={activeTab === 'goalkeepers' ? 'active' : ''}>
                Top Goalkeepers
            </button>
            <button onClick={() => onChangeTab('topPlayers')} className={activeTab === 'topPlayers' ? 'active' : ''}>
                Top Players
            </button>
            <button onClick={() => onChangeTab('hatTricks')} className={activeTab === 'hatTricks' ? 'active' : ''}>
                Hat-tricks
            </button>
        </div>
    );
};

const TopScorers = ({ goalScorers, logos }) => {
    const sortedScorers = Object.entries(goalScorers)
        .map(([name, stats]) => ({ name, ...stats }))
        .sort((a, b) => b.goals - a.goals || b.assists - a.assists) // Sort by goals first, then assists
        .slice(0, 20);

    return (
        <div className="league-top-scorers">
            <h3>Top 20 Scorers</h3>
            <table className="stats-table">
                <thead>
                    <tr>
                        <th>Pos</th>
                        <th>Player</th>
                        <th>Team</th>
                        <th>Games</th>
                        <th>Goals</th>
                        <th>Assists</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedScorers.map((scorer, index) => {
                        const fullName = getFullName(scorer.name, scorer.fullName, scorer.country) || scorer.name;
                        const teamLogo = logos.find(logo => logo.club === scorer.team)?.logo || '';

                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td className="player-cell">
                                    <PlayerPhoto player={scorer} className="player-photo" />
                                    <span>{fullName}</span>
                                </td>
                                <td className="team-cell">
                                    <img src={teamLogo} alt={scorer.team} className="team-logo" />
                                    <span>{scorer.team}</span>
                                </td>
                                <td>{scorer.appearances}</td>
                                <td>{scorer.goals}</td>
                                <td>{scorer.assists}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

const TopAssisters = ({ assisters, logos }) => {
    const sortedAssisters = Object.entries(assisters)
        .map(([name, stats]) => ({ name, ...stats }))
        .sort((a, b) => b.assists - a.assists)
        .slice(0, 20); // Get top 20

    return (
        <div className="league-top-scorers">
            <h3>Top 20 Assisters</h3>
            <table className="stats-table">
                <thead>
                    <tr>
                        <th>Pos</th>
                        <th>Player</th>
                        <th>Team</th>
                        <th>Games</th>
                        <th>Assists</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedAssisters.map((assister, index) => {
                        const fullName = getFullName(assister.name, assister.fullName, assister.country) || assister.name;
                        const teamLogo = logos.find(logo => logo.club === assister.team)?.logo || '';

                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td className="player-cell">
                                    <PlayerPhoto player={assister} className="player-photo" />
                                    <span>{fullName}</span>
                                </td>
                                <td className="team-cell">
                                    <img src={teamLogo} alt={assister.team} className="team-logo" />
                                    <span>{assister.team}</span>
                                </td>
                                <td>{assister.appearances}</td>
                                <td>{assister.assists}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

const BestGoalkeepers = ({ topGoalkeepers, logos }) => {
    return (
        <div className="league-top-scorers">
            <h3>Top 20 Goalkeepers</h3>
            <table className="stats-table">
                <thead>
                    <tr>
                        <th>Pos</th>
                        <th>Player</th>
                        <th>Team</th>
                        <th>Games</th>
                        <th>Clean Sheets</th>
                    </tr>
                </thead>
                <tbody>
                    {topGoalkeepers.map((goalkeeper, index) => {
                        const fullName = goalkeeper.fullName ? getFullName(goalkeeper.name, goalkeeper.fullName, goalkeeper.country) : goalkeeper.name;
                        const teamLogo = logos.find(logo => logo.club === goalkeeper.team)?.logo || '';

                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td className="player-cell">
                                    <PlayerPhoto player={goalkeeper} className="player-photo" />
                                    <span>{fullName}</span>
                                </td>
                                <td className="team-cell">
                                    <img src={teamLogo} alt={goalkeeper.team} className="team-logo" />
                                    <span>{goalkeeper.team}</span>
                                </td>
                                <td>{goalkeeper.appearances}</td>
                                <td>{goalkeeper.cleanSheets}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

const BestPerformingPlayers = ({ topPlayers, logos }) => {
    return (
        <div className="league-top-scorers">
            <h3>Top 30 Players By Rating</h3>
            <table className="stats-table">
                <thead>
                    <tr>
                        <th>Pos</th>
                        <th>Player</th>
                        <th>Team</th>
                        <th>Games</th>
                        <th>Rating</th>
                    </tr>
                </thead>
                <tbody>
                    {topPlayers.map((topPlayer, index) => {
                        const teamLogo = logos.find(logo => logo.club === topPlayer.team)?.logo || '';
                        const fullName = topPlayer.fullName ? getFullName(topPlayer.name, topPlayer.fullName, topPlayer.country) : topPlayer.name;

                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td className="player-cell">
                                    <PlayerPhoto player={topPlayer} className="player-photo" />
                                    <span>{fullName}</span>
                                </td>
                                <td className="team-cell">
                                    <img src={teamLogo} alt={topPlayer.team} className="team-logo" />
                                    <span>{topPlayer.team}</span>
                                </td>
                                <td>{topPlayer.appearances}</td>
                                <td>{topPlayer.averageRating.toFixed(2)}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

const HatTricks = ({ hatTricks, logos }) => {
    return (
        <div className="league-top-scorers hat-tricks">
            <h3>Hat-Tricks</h3>
            {Object.keys(hatTricks).map(matchDay => (
                <div key={matchDay}>
                    <h4>{Number.isInteger(parseInt(matchDay)) ? `Matchday ${matchDay}` : matchDay}</h4>
                    <table className="stats-table">
                        <thead>
                            <tr>
                                <th>Player</th>
                                <th>For</th>
                                <th>Against</th>
                                <th>Result</th>
                                <th>Times</th>
                            </tr>
                        </thead>
                        <tbody>
                            {hatTricks[matchDay].map((hatTrick, index) => {
                                const forTeamLogo = logos.find(logo => logo.club === hatTrick.forTeam)?.logo || '';
                                const againstTeamLogo = logos.find(logo => logo.club === hatTrick.againstTeam)?.logo || '';
                                const fullName = getFullName(hatTrick.player, hatTrick.fullName, hatTrick.country);

                                return (
                                    <tr key={index}>
                                        <td className="player-cell">
                                            <img src={hatTrick.photo} alt={hatTrick.player} className="player-photo" />
                                            <span>{fullName}</span>
                                            <img src={hatTrick.flag} alt="Nationality" className="player-flag" />
                                        </td>
                                        <td className="team-cell">
                                            <img src={forTeamLogo} alt={hatTrick.forTeam} className="team-logo" />
                                            <span>{hatTrick.forTeam}</span>
                                        </td>
                                        <td className="team-cell">
                                            <img src={againstTeamLogo} alt={hatTrick.againstTeam} className="team-logo" />
                                            <span>{hatTrick.againstTeam}</span>
                                        </td>
                                        <td>{hatTrick.scoreline} {hatTrick.homeOrAway}</td>
                                        <td>{hatTrick.times.map(time => `${time}'`).join(', ')}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            ))}
        </div>
    );
};

export const LeagueTable = ({ teams, logos }) => {
    return (
        <table className="league-table">
            <thead>
                <tr>
                    <th className="highlight-header" title="Position">Pos</th>
                    <th className="highlight-header" title="Club Name">Club</th>
                    <th className="highlight-header" title="Matches Played">MP</th>
                    <th className="highlight-header" title="Wins">W</th>
                    <th className="highlight-header" title="Draws">D</th>
                    <th className="highlight-header" title="Losses">L</th>
                    <th className="highlight-header" title="Goals For">GF</th>
                    <th className="highlight-header" title="Goals Against">GA</th>
                    <th className="highlight-header" title="Goal Difference">GD</th>
                    <th className="highlight-header" title="Points">Pts</th>
                </tr>
            </thead>
            <tbody>
                {teams.map((team, index) => {
                    const teamLogo = logos.find(logo => logo.club === team.displayName)?.logo;

                    return (
                        <tr key={`${team.team_id}-${index}`}>
                            <td>{index + 1}</td>
                            <td>
                                <img src={teamLogo} alt={`${team.displayName} logo`} className="club-logo" />
                                {`${team.displayName} `}
                                {team.tiebreakerExplanation && (
                                    <span className="tiebreaker-tooltip" title={team.tiebreakerExplanation}>
                                        ⓘ
                                    </span>
                                )}
                            </td>
                            <td>{team.played}</td>
                            <td>{team.wins}</td>
                            <td>{team.draws}</td>
                            <td>{team.losses}</td>
                            <td>{team.goals_for}</td>
                            <td>{team.goals_against}</td>
                            <td>{team.goal_difference}</td>
                            <td>{team.points}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    )
}

const renderEventIcons = (playerName, events, isSubstitute = false) => {
    const playerEvents = getPlayerEvents(events, playerName);

    /// Track the number of goals
    let goalCount = 0;
    let assistCount = 0;
    let hasYellowCard = false;
    let hasRedCard = false;
    let hasYellowToRed = false;
    let hasOwnGoal = false;
    let hasSubstitution = false;

    // Determine which events to display and count goals
    playerEvents.forEach((event) => {
        switch (event.type) {
            case 'goal':
                if (event.name.includes('(o.g.)')) {
                    if (goalCount === 0) {
                        hasOwnGoal = true;
                    }
                } else if (event.name.includes(playerName)) {  // Check if the player is the goal scorer
                    goalCount++;
                } else if (event.assister && event.assister.includes(playerName)) {  // Check if the player is the assister
                    assistCount++;
                }
                break;
            case 'yellowCard':
                hasYellowCard = true;
                break;
            case 'redCard':
                hasRedCard = true;
                break;
            case 'yellowToRed':
                hasYellowToRed = true;
                break;
            case 'substitution':
                hasSubstitution = true;
                break;
            default:
                break;
        }
    });

    const iconClass = isSubstitute ? 'substitute-event-icon' : 'event-icon';
    const goalIconClass = isSubstitute ? 'substitute-goal-icon' : 'goal-icon';
    const goalCountClass = isSubstitute ? 'substitute-goal-count' : 'goal-count';
    const assistCountClass = isSubstitute ? 'substitute-assist-count' : 'assist-count';
    const assistIconClass = isSubstitute ? 'substitute-assist-icon' : 'assist-icon';

    return (
        <>
            {(assistCount > 0) && (
                <span className="assist-icon-wrapper">
                    <img src={Assist} alt="assist" className={`${iconClass} ${assistIconClass}`} />
                    {assistCount > 1 && (
                        <span className={assistCountClass}>{assistCount}</span>
                    )}
                </span>
            )}
            {(goalCount > 0 || hasOwnGoal) && (
                <span className="goal-icon-wrapper">
                    <img src={Ball} alt="goal" className={`${iconClass} ${goalIconClass}`} />
                    {/* If own goal, display 'OG', otherwise display the goal count */}
                    {goalCount > 1 && !hasOwnGoal && (
                        <span className={goalCountClass}>{goalCount}</span>
                    )}
                    {hasOwnGoal && (
                        <span className={goalCountClass}>OG</span>
                    )}
                </span>
            )}
            {hasYellowToRed ? (
                <img src={YellowAndRed} alt="yellow-to-red" className={`${iconClass} yellow-to-red-icon`} />
            ) : hasRedCard ? (
                <img src={RedCard} alt="red-card" className={`${iconClass} red-card-icon`} />
            ) : hasYellowCard ? (
                <img src={YellowCard} alt="yellow-card" className={`${iconClass} yellow-card-icon`} />
            ) : hasSubstitution && !isSubstitute && (
                <span className="out-sub-icon">
                    <img src={Out} alt="out-sub" />
                </span>
            )}
        </>
    );
};

const shortenName = (name) => {
    if (!name || name.includes('.') || !name.includes(' ')) {
        return name;
    }

    const firstName = name.split(' ')[0];
    const firstLetter = `${firstName[0]}.`;

    return name.replace(firstName, firstLetter);
};

const getPlayerEvents = (events, playerName) => {
    if (!playerName) {
        console.error('Player name not defined');
    }
    return events.filter(event => event.name.includes(playerName) || (event.assister && event.assister === playerName));
}

export const FormationView = ({ team, teamColor, isReversed = false, result, handlePlayerClick }) => {

    const formation = team.positionsData;
    const positions = ['Goalkeeper', 'Defender', 'dMidfielder', 'Midfielder', 'aMidfielder', 'Striker'];
    const positionGroups = isReversed ? positions.reverse() : positions;

    return (
        <div className="formation-container">
            {positionGroups.map((positionGroup, groupIndex) => {
                return (
                    <div key={groupIndex} className="position-row">
                        {Object.keys(formation)
                            .filter(key => key.startsWith(positionGroup))
                            .sort((a, b) => !isReversed ? parseInt(b.split('-')[1]) - parseInt(a.split('-')[1]) : parseInt(a.split('-')[1]) - parseInt(b.split('-')[1]))
                            .map(key => {
                                const player = formation[key];
                                const isCaptain = player.name === team.captain.name;
                                const isPlayerOfMatch = player.name === result.manOfTheMatch.player;

                                return (
                                    <div key={key} className="player-container">
                                        <div
                                            className="player-circle"
                                            style={{ backgroundColor: teamColor, border: '2px solid white' }}
                                            onClick={() => handlePlayerClick(player, team)}
                                            onTouchEnd={() => handlePlayerClick(player, team)}
                                        >
                                            <div className="player-position" >{player.clubNumber !== 0 ? player.clubNumber : player.currentPosition.name}</div>
                                            {renderEventIcons(player.name, result.events)}
                                        </div>
                                        <div className="player-name">
                                            {shortenName(player.name)}
                                            {isCaptain && (
                                                <img src={Captain} alt="Captain" className="captain-badge" />
                                            )}
                                            {isPlayerOfMatch && (
                                                <img src={motmAward} alt="Man of the Match" className="motm-badge" />
                                            )}
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                );
            })}
        </div>
    );
};

export const renderSubstitutes = (homeTeam, awayTeam, homeLogo, awayLogo, events, handlePlayerClick) => {
    const substitutions = events.filter(event => event.type === 'substitution');
    const homeSubstitutes = homeTeam.bench;
    const awaySubstitutes = awayTeam.bench;

    const wasSubstituted = (sub, teamSubstitutions) => {
        return teamSubstitutions.some(subPlayer => subPlayer.substitute === sub.name);
    };

    const sortSubstitutedByMinute = (subs, teamSubstitutions) => {
        // Extract substituted players
        const substitutedPlayers = subs.filter(sub => wasSubstituted(sub, teamSubstitutions))
            .sort((a, b) => {
                const eventA = teamSubstitutions.find(event => event.substitute === a.name);
                const eventB = teamSubstitutions.find(event => event.substitute === b.name);
                return parseMinute(eventA.minute) - parseMinute(eventB.minute);
            });

        // Extract players who were not substituted
        const nonSubstitutedPlayers = subs.filter(sub => !wasSubstituted(sub, teamSubstitutions));

        // Return the combined list with substituted players first
        return [...substitutedPlayers, ...nonSubstitutedPlayers];
    };

    const sortedHomeSubstitutes = sortSubstitutedByMinute(homeSubstitutes, substitutions.filter(sub => sub.team === homeTeam.displayName));
    const sortedAwaySubstitutes = sortSubstitutedByMinute(awaySubstitutes, substitutions.filter(sub => sub.team === awayTeam.displayName));

    return (
        <div className="substitutes-section">
            <div className="substitutes-header">
                <div className="team-logo">
                    <img src={homeLogo} alt={`${homeTeam.displayName} logo`} className="team-logo-img" />
                </div>
                <div className="substitutes-title">SUBSTITUTES</div>
                <div className="team-logo">
                    <img src={awayLogo} alt={`${awayTeam.displayName} logo`} className="team-logo-img" />
                </div>
            </div>
            <div className="substitutes-content">
                <div className="home-substitutes">
                    {sortedHomeSubstitutes.map((sub, index) => {
                        const wasSubbed = wasSubstituted(sub, substitutions.filter(sub => sub.team === homeTeam.displayName));

                        return (
                            <div key={index}
                                className="substitute"
                                onClick={() => handlePlayerClick(sub, homeTeam, true)}
                                onTouchEnd={() => handlePlayerClick(sub, homeTeam, true)}
                            >
                                <strong style={{ marginRight: '10px' }}>
                                    {parseInt(sub.clubNumber) !== 0 ? sub.clubNumber : sub.position.split(';')[0]}
                                </strong>
                                {shortenName(sub.name)}
                                {renderEventIcons(sub.name, events, true, false)}
                                {/* Conditionally render the in-sub-icon if wasSubstituted is true */}
                                {wasSubbed && (
                                    <span className="in-sub-icon right">
                                        <img src={In} alt="in-sub" />
                                    </span>
                                )}
                            </div>
                        );
                    })}
                </div>
                <div className="away-substitutes">
                    {sortedAwaySubstitutes.map((sub, index) => {
                        const wasSubbed = wasSubstituted(sub, substitutions.filter(sub => sub.team === awayTeam.displayName));

                        return (

                            <div key={index}
                                className="substitute"
                                onClick={() => handlePlayerClick(sub, awayTeam, true)}
                                onTouchEnd={() => handlePlayerClick(sub, awayTeam, true)}
                            >
                                {wasSubbed && (
                                    <span className="in-sub-icon left">
                                        <img src={In} alt="in-sub" />
                                    </span>
                                )}
                                <div className="away-substitute-wrapper">
                                    {renderEventIcons(sub.name, events, true)}
                                </div>
                                {shortenName(sub.name)}
                                <strong style={{ marginLeft: '10px' }}>{parseInt(sub.clubNumber) !== 0 ? sub.clubNumber : sub.position.split(';')[0]}</strong>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const getTeamAbbreviation = (displayName) => {
    if (!displayName) return '';
    if (displayName.length < 3) return displayName;

    const prefixList = ['FC', 'Vf', 'Real', 'FSV', 'TSG', 'AC', 'AS', 'Le', 'Stade de', 'Olympique', 'Hellas'];
    const nameParts = displayName.split(' ');

    const deIndex = nameParts.indexOf('de');
    if (deIndex !== -1 && nameParts.length > deIndex + 1) {
        return nameParts[deIndex + 1].slice(0, 3).toUpperCase(); // Return the first 3 letters after 'de'
    }

    // If the displayName starts with any of the specified prefixes, use the second word
    if (prefixList.includes(nameParts[0]) && nameParts.length > 1) {
        return nameParts[1].slice(0, 3).toUpperCase();
    }

    return displayName.slice(0, 3).toUpperCase();
};

const determineBackgroundColor = (result, team) => {
    if (!team) {
        return '';
    }
    if (result.homeGoals === result.awayGoals) {
        return 'draw-background';
    }
    if (result.home === team && result.homeGoals > result.awayGoals) {
        return 'win-background'; // Home team win
    }
    if (result.away === team && result.awayGoals > result.homeGoals) {
        return 'win-background'; // Away team win
    }
    return 'loss-background'; // Loss
};

export const MatchResult = ({ result, logos, team = null, toggleFormation, isFormationVisible, stageKey = '' }) => {
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [playerShots, setPlayerShots] = useState(null);
    const [teamLogo, setTeamLogo] = useState(null);
    const [isSubstitute, setIsSubstitute] = useState(false);
    const shotStats = result.shots;

    const handlePlayerClick = (player, team, isSub = false) => {
        if (!result.ratings[player.name]) return;
        const rating = result.ratings[player.name].rating;
        setSelectedPlayer({ ...player, rating: rating })
        let playerShots = shotStats[`${player.name}+${team.displayName}`];
        //console.log('Player shots:', playerShots);
        if (!playerShots) {
            playerShots = {
                name: player.name,
                type: 'shot',
                shots: 0,
                shotsOnTarget: 0,
            }
        }
        setPlayerShots(playerShots);
        setIsSubstitute(isSub);
        const teamLogo = logos.find(logo => logo.club === team.displayName);
        setTeamLogo(teamLogo);
    }

    const generateFormationString = (formation) => {
        //console.log('Current formation', formation);
        if (!formation) {
            return '';
        }

        const positionGroups = ['Defender', 'dMidfielder', 'Midfielder', 'aMidfielder', 'Striker'];
        const formationArray = [];

        positionGroups.forEach(positionGroup => {
            const playersInPosition = Object.keys(formation).filter(key => key.startsWith(positionGroup));
            if (playersInPosition.length > 0) {
                formationArray.push(playersInPosition.length);
            }
        });

        const formationString = formationArray.join('-');
        return formationString;
    };

    const renderMatchResult = (result, index) => {
        const homeLogo = logos.find(logo => logo.club === result.home)?.logo;
        const awayLogo = logos.find(logo => logo.club === result.away)?.logo;

        const homeAbbreviation = getTeamAbbreviation(result.home);
        const awayAbbreviation = getTeamAbbreviation(result.away);

        const homeFormation = generateFormationString(result.team1.positionsData);
        const awayFormation = generateFormationString(result.team2.positionsData);

        const date = new Date(result.date);
        const formattedDate = date.toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });

        const backgroundColor = team ? determineBackgroundColor(result, team) : '';

        return (
            <div className={`match-result-container ${backgroundColor}`} key={index}>
                <div className="match-date"><strong>{formattedDate}</strong></div>
                <div className="scoreline">
                    <strong>{result.homeGoals} - {result.awayGoals}</strong>
                </div>
                <div className="match-result">
                    <div className="team-container">
                        <div className="team-logo-abbreviation">
                            <img src={homeLogo} alt={`${result.home} logo`} className="club-logo" />
                            <div className="team-abbreviation">{homeAbbreviation}</div>
                        </div>
                        <div className="team-events-container">
                            {renderEvents(result.events, result.home, result.injuries)}
                        </div>
                    </div>
                    <span className="vs">vs</span>
                    <div className="team-container">
                        <div className="team-logo-abbreviation">
                            <img src={awayLogo} alt={`${result.away} logo`} className="club-logo" />
                            <div className="team-abbreviation">{awayAbbreviation}</div>
                        </div>
                        <div className="team-events-container">
                            {renderEvents(result.events, result.away, result.injuries)}
                        </div>
                    </div>
                </div>
                <div className="man-of-the-match">
                    <strong>Man of the Match:</strong> {getFullName(result.manOfTheMatch.player, result.manOfTheMatch.fullName, result.manOfTheMatch.country)} (<i>{result.manOfTheMatch.team}</i>)
                </div>
                <button className="formation-toggle-button" onClick={() => toggleFormation(stageKey !== '' ? `${stageKey}-${result.id}` : result.id)}>
                    {isFormationVisible ? 'Hide Formation' : 'Show Formation'}
                </button>
                {isFormationVisible && (
                    <div className="formations-container" style={{ backgroundColor: 'green' }}>
                        <div className="formation-header">
                            <div className="team-info">
                                <img src={homeLogo} alt={`${result.home} logo`} className="club-logo" />
                                <span className="team-name">{result.home}</span>
                            </div>
                            <div className="team-formation-string">{homeFormation}</div>
                        </div>
                        <div className="formation-container">
                            <div className="team-formation">
                                <FormationView team={result.team1} teamColor="orange" result={result} handlePlayerClick={handlePlayerClick} />
                            </div>
                            <div className="team-formation">
                                <FormationView team={result.team2} teamColor="blue" isReversed={true} result={result} handlePlayerClick={handlePlayerClick} />
                            </div>
                            {renderSubstitutes(result.team1, result.team2, homeLogo, awayLogo, result.events, handlePlayerClick)}
                        </div>
                        <div className="formation-header">
                            <div className="team-info">
                                <img src={awayLogo} alt={`${result.away} logo`} className="club-logo" />
                                <span className="team-name">{result.away}</span>
                            </div>
                            <div className="team-formation-string">{awayFormation}</div>
                        </div>
                    </div>
                )}

                {selectedPlayer && (
                    <PlayerStatsMenu
                        player={selectedPlayer}
                        events={result.events}
                        shots={playerShots}
                        isSubstitute={isSubstitute}
                        logo={teamLogo}
                        onClose={() => setSelectedPlayer(null)}
                    />
                )}
            </div>
        );
    };

    return renderMatchResult(result);
};

const LeagueSimulator = () => {
    const [selectedLeague, setSelectedLeague] = useState(leagues[0]);
    const [teams, setTeams] = useState([])
    const [logos, setLogos] = useState([]);
    const [playerData, setPlayerData] = useState([]);
    const [leagueTable, setLeagueTable] = useState([]);
    const [fixtureResults, setFixtureResults] = useState([]);
    const [currentMatchday, setCurrentMatchday] = useState(0);
    const [goalScorers, setGoalScorers] = useState({});
    const [assisters, setAssisters] = useState({});
    const [topGoalkeepers, setTopGoalkeepers] = useState([]);
    const [topPlayers, setTopPlayers] = useState([]);
    const [hatTricks, setHatTricks] = useState([]);
    const [teamStats, setTeamStats] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [scheduledFixtures, setScheduledFixtures] = useState(null);
    const [searchType, setSearchType] = useState('matchup');
    const [activeTab, setActiveTab] = useState('fixtures');
    const [arePlayersLoading, setArePlayersLoading] = useState(false);
    const [knockoutResults, setKnockoutResults] = useState(null);
    const [isSimulationLoading, setIsSimulationLoading] = useState(false);
    const [showCurrentKnockout, setShowCurrentKnockout] = useState(false);
    const [formattedTeams, setFormattedTeams] = useState([]);
    const [knockoutRound, setKnockoutRound] = useState('8');

    const checkCaptains = (teams) => {
        if (!teams) {
            console.error('Teams empty');
            return;
        }
        teams.forEach(team => {
            if (!team.positionsData.find(player => parseInt(player.id) === parseInt(team.captain))) {
                console.log(`${team.team_name} does not have the correct captain ${team.captain} in`, team.positionsData);
            }
        });
    }

    const handleLeagueChange = async (event) => {
        const getCLResponse = async (url) => {
            const actualId = 1;
            try {
                const response = await axios.get(`${backendUrl}/api/league-teams/${url}/${actualId}`, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                });
                const teams = response.data.teams;
                // console.log('CL teams:', teams.map(team => team.team_name));
                checkCaptains(teams);
                setTeams(teams);
                setArePlayersLoading(false);
                return teams;
            } catch (error) {
                console.error("There was an error fetching the teams from Champions League!", error);
                return [];
            }
        };
        setSearchResults([]);
        setLeagueTable([]);
        const leagueId = parseInt(event.target.value, 10);
        const league = leagues.find(l => l.id === leagueId);
        setSelectedLeague(league);
        setArePlayersLoading(true);
        if (league.id === 99) {
            await getCLResponse('uefa-league');
            setShowCurrentKnockout(false);
        } else if (leagueId === 69) {
            const currentTeams = await getCLResponse('current-uefa-league');
            const formattedTeams = await simulateLeague(69, currentTeams, playerData);
            console.log("Formatted teams:", formattedTeams);
            setShowCurrentKnockout(true);
            setFormattedTeams(formattedTeams);
        } else {
            try {
                const response = await axios.get(`${backendUrl}/api/league-teams/league/${leagueId}`, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                });

                //console.log('League response data:', response.data);

                if (response.data) {
                    checkCaptains(response.data);
                    setTeams(response.data);
                    setArePlayersLoading(false);
                } else {
                    console.error(response.data);
                }
            } catch (error) {
                console.error("There was an error fetching the teams!", error);
            }
            setShowCurrentKnockout(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const ipAddress = await fetchUserIp();
            if (!ipAddress) {
                console.error("Could not fetch IP address.");
                return;
            }
            const cachedData = await getData(ipAddress);
            setPlayerData(cachedData.data.players);
        };

        fetchData();
        fetchLogos(setLogos, null);
    }, []);

    const MatchdayResults = ({ matchdayResults, matchday, logos, allFixtures }) => {
        const [selectedTeam, setSelectedTeam] = useState('Select Team');
        const [team1, setTeam1] = useState('Select Team 1');
        const [team2, setTeam2] = useState('Select Team 2');
        const [visibleFormationId, setVisibleFormationId] = useState(null);

        const onToggleSearch = (value) => {
            setSearchType(value)
            setSelectedTeam('Select Team');
        }

        const toggleFormation = (matchId) => {
            if (visibleFormationId === matchId) {
                setVisibleFormationId(null); // Close the currently opened formation
            } else {
                setVisibleFormationId(matchId); // Open the new formation and close any other
            }
        };

        const teams = fixtureResults[0].reduce((acc, fixture) => {
            // Add home team if not already in the accumulator
            if (!acc.includes(fixture.home)) {
                acc.push(fixture.home);
            }
            // Add away team if not already in the accumulator
            if (!acc.includes(fixture.away)) {
                acc.push(fixture.away);
            }
            return acc;
        }, []).sort((a, b) => a.localeCompare(b));

        const handleSearchByMatchup = () => {
            if (team1 === 'Select Team 1' || team2 === 'Select Team 2') {
                alert("Please select both teams.");
                return;
            }
            if (team1 === team2) {
                alert("You can't select the same team for both Team 1 and Team 2!");
                return;
            }

            const results = allFixtures.flatMap(fixtures =>
                fixtures.filter(result =>
                    (result.home === team1 && result.away === team2) ||
                    (result.home === team2 && result.away === team1)
                )
            );

            if (results.length === 0) {
                alert('No matches were found between the teams');
            }

            setSearchResults(results);
        };

        const handleSearchByTeam = () => {
            if (selectedTeam === 'Select Team') {
                alert('Please select a team');
                return;
            }

            //console.log(`Selected team: ${selectedTeam}`);

            const results = allFixtures.flatMap(fixtures =>
                fixtures.filter(result =>
                    result.home === selectedTeam || result.away === selectedTeam
                )
            );

            if (results.length === 0) {
                alert('No matches were found involving the team.');
            }

            //console.log('Results:', results);

            setSearchResults(results);
            const teamStatistics = getTeamStatistics(leagueTable, selectedTeam, results);
            //console.log('Team stats:', teamStatistics);
            setTeamStats(teamStatistics);
        };

        const formatDate = (date) => {
            const [year, month, day] = date.split('-');
            return `${month}/${day}/${year}`;
        };

        const winningStreak = teamStats.longestWinningStreak;
        const unbeatenStreak = teamStats.longestUnbeatenStreak;
        const winlessStreak = teamStats.longestWinlessStreak;
        const losingStreak = teamStats.longestLosingStreak;

        return (
            <div className="matchday-results">
                <h3>Matchday {matchday + 1}</h3>

                <div className="search-type-container">
                    <h4>Search Type</h4>
                    <select value={searchType} onChange={(e) => onToggleSearch(e.target.value)}>
                        <option value="matchup">Find a Specific Matchup</option>
                        <option value="teamResults">See All Match Results of Team</option>
                    </select>
                </div>

                {searchType === 'matchup' && (
                    <div className="search-container">
                        <h4>Find a Match Result</h4>
                        <div className="search-dropdowns">
                            <div className="team-dropdown">
                                <label htmlFor="team1">Team 1</label>
                                <select id="team1" value={team1} onChange={(e) => setTeam1(e.target.value)}>
                                    <option value="Select Team 1">Select Team 1</option>
                                    {teams.map((team, index) => (
                                        <option key={index} value={team}>
                                            {team}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <span className="vs">vs</span>

                            <div className="team-dropdown">
                                <label htmlFor="team2">Team 2</label>
                                <select id="team2" value={team2} onChange={(e) => setTeam2(e.target.value)}>
                                    <option value="Select Team 2">Select Team 2</option>
                                    {teams.map((team, index) => (
                                        <option key={index} value={team}>
                                            {team}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <button onClick={handleSearchByMatchup}>Search</button>
                    </div>
                )}

                {searchType === 'teamResults' && (
                    <div className="search-container">
                        <h4>See All Match Results of Team</h4>
                        <div className="team-dropdown">
                            <label htmlFor="selectedTeam">Select Team</label>
                            <select className="team-results-select" id="selectedTeam" value={selectedTeam} onChange={(e) => setSelectedTeam(e.target.value)}>
                                <option value="Select Team">Select Team</option>
                                {teams.map((team, index) => (
                                    <option key={index} value={team}>
                                        {team}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <button onClick={handleSearchByTeam}>Search</button>
                    </div>
                )}

                {/* Match Results for Search */}
                {searchResults && searchResults.length > 0 ? (
                    <div>
                        {searchType === 'teamResults' && teamStats && searchResults.length > 2 && (
                            <div className="team-stats">
                                <h3><strong>Team Statistics:</strong> <i>{teamStats.team}</i></h3>

                                {/* League Position */}
                                <div>
                                    <strong>League Position:</strong> {teamStats.position}
                                </div>
                                {/*Longest winning streak */}
                                <div>
                                    <strong>Longest Winning Streak:</strong> {winningStreak.length} {winningStreak.length === 1 ? 'game' : 'games'} {winningStreak.length > 1 ? <i>({formatDate(winningStreak.startDate)} - {formatDate(winningStreak.endDate)})</i> : ''}
                                </div>
                                <div>
                                    <strong>Longest Unbeaten Streak:</strong> {unbeatenStreak.length} {unbeatenStreak.length === 1 ? 'game' : 'games'} {unbeatenStreak.length > 1 ? <i>({formatDate(unbeatenStreak.startDate)} - {formatDate(unbeatenStreak.endDate)})</i> : ''}
                                </div>
                                <div>
                                    <strong>Longest Winless Streak:</strong> {winlessStreak.length} {winlessStreak.length === 1 ? 'game' : 'games'} {winlessStreak.length > 1 ? <i>({formatDate(winlessStreak.startDate)} - {formatDate(winlessStreak.endDate)})</i> : ''}
                                </div>
                                <div>
                                    <strong>Longest Losing Streak:</strong> {losingStreak.length} {losingStreak.length === 1 ? 'game' : 'games'} {losingStreak.length > 1 ? <i>({formatDate(losingStreak.startDate)} - {formatDate(losingStreak.endDate)})</i> : ''}
                                </div>

                                {/* Largest Home Victory */}
                                <div>
                                    <strong>Largest Home Victory:</strong>
                                    {teamStats.largestHomeVictory && teamStats.largestHomeVictory.length > 0 ? (
                                        teamStats.largestHomeVictory.map((victory, index) => (
                                            <div key={index} className="victory-entry">
                                                {victory.scoreline} vs <i>{victory.opponent}</i> ({formatDate(victory.date)})
                                            </div>
                                        ))
                                    ) : ' No home victories'}
                                </div>

                                {/* Largest Away Victory */}
                                <div>
                                    <strong>Largest Away Victory:</strong>
                                    {teamStats.largestAwayVictory && teamStats.largestAwayVictory.length > 0 ? (
                                        teamStats.largestAwayVictory.map((victory, index) => (
                                            <div key={index} className="victory-entry">
                                                {victory.scoreline} vs <i>{victory.opponent}</i> ({formatDate(victory.date)})
                                            </div>
                                        ))
                                    ) : ' No away victories'}
                                </div>

                                {/* Largest Home Loss */}
                                <div>
                                    <strong>Largest Home Loss:</strong>
                                    {teamStats.largestHomeLoss && teamStats.largestHomeLoss.length > 0 ? (
                                        teamStats.largestHomeLoss.map((loss, index) => (
                                            <div key={index} className="loss-entry">
                                                {loss.scoreline} vs <i>{loss.opponent}</i> ({formatDate(loss.date)})
                                            </div>
                                        ))
                                    ) : ' No home losses'}
                                </div>

                                {/* Largest Away Loss */}
                                <div>
                                    <strong>Largest Away Loss:</strong>
                                    {teamStats.largestAwayLoss && teamStats.largestAwayLoss.length > 0 ? (
                                        teamStats.largestAwayLoss.map((loss, index) => (
                                            <div key={index} className="loss-entry">
                                                {loss.scoreline} vs <i>{loss.opponent}</i> ({formatDate(loss.date)})
                                            </div>
                                        ))
                                    ) : ' No away losses'}
                                </div>

                                {/* Top Scorers */}
                                <div>
                                    <strong>Top Scorer(s):</strong>
                                    {teamStats.top5Scorers && Object.keys(teamStats.top5Scorers).length > 0 ? (
                                        <div>
                                            {Object.entries(teamStats.top5Scorers)
                                                .sort(([goalsA], [goalsB]) => goalsB - goalsA)  // Sort by goals in descending order
                                                .map(([goals, players], index) => (
                                                    <div key={index} className="top-scorers-entry">
                                                        <span>
                                                            <i>{players.join(', ')}</i> {/* List players separated by commas */}
                                                        </span>
                                                        <span>
                                                            {' '}({goals} goals) {/* Display the unique goal count */}
                                                        </span>
                                                    </div>
                                                ))}
                                        </div>
                                    ) : 'No goals scored yet'}
                                </div>
                            </div>
                        )}

                        <div className="search-results">
                            {searchResults.map((result, index) => (
                                <MatchResult
                                    key={index}
                                    result={result}
                                    logos={logos}
                                    team={searchType === 'teamResults' ? teamStats.team : null}
                                    toggleFormation={toggleFormation}
                                    isFormationVisible={visibleFormationId === result.id}
                                />
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="matchday-fixtures">
                        {matchdayResults.map((result, index) => (
                            <MatchResult
                                key={index}
                                result={result}
                                logos={logos}
                                toggleFormation={toggleFormation}
                                isFormationVisible={visibleFormationId === result.id}
                            />
                        ))}
                    </div>
                )}
            </div>
        );
    };

    const MatchdayNavigation = ({ currentMatchday, totalMatchdays, onMatchdayChange }) => {
        const handlePrevClick = () => {
            if (currentMatchday > 0) {
                onMatchdayChange(currentMatchday - 1);
            }
        };

        const handleNextClick = () => {
            if (currentMatchday < totalMatchdays - 1) {
                onMatchdayChange(currentMatchday + 1);
            }
        };

        const handleDropdownChange = (event) => {
            const matchday = parseInt(event.target.value, 10);
            onMatchdayChange(matchday);
        }

        return (
            <div className="matchday-navigation">
                <button onClick={handlePrevClick} disabled={currentMatchday === 0}>
                    &larr; Previous
                </button>
                <select onChange={handleDropdownChange} value={currentMatchday}>
                    {Array.from({ length: totalMatchdays }, (_, i) => (
                        <option key={i} value={i}>
                            Matchday {i + 1}
                        </option>
                    ))}
                </select>
                <button onClick={handleNextClick} disabled={currentMatchday === totalMatchdays - 1}>
                    Next &rarr;
                </button>
            </div>
        );
    };

    const handleLeagueClick = () => {
        if (selectedLeague.id === leagues[0].id) {
            handleLeagueChange({ target: { value: leagues[0].id } });
        }
    };

    const simulateLeagueMatches = async () => {
        if (selectedLeague.id === 99) {
            setIsSimulationLoading(true);
            setTimeout(async () => {
                const startTime = performance.now();
                const leagueResults = await simulateLeague(selectedLeague.id, teams, playerData);
                //console.log('League results:', leagueResults);
                const endTime = performance.now(); // End timing
                const duration = ((endTime - startTime) / 1000).toFixed(2);
                console.log(`Simulation took ${duration} seconds`);
                setScheduledFixtures(leagueResults.scheduledFixtures);
                setFormattedTeams(leagueResults.formattedTeams);
                setCurrentMatchday(0);
                setSearchResults([]);
                setFixtureResults([]);
                setKnockoutResults([]);
                setActiveTab('fixtures');
                setLeagueTable(leagueResults.leagueTable);
                setIsSimulationLoading(false);
            }, 100);
        } else if (selectedLeague.id === 69) {
            if (knockoutRound === '8') {
                setKnockoutResults(championsLeagueKnockoutFixtures);
            }
            setIsSimulationLoading(true);
            const results = await simulateChampionsLeagueFixtures(500, formattedTeams, knockoutRound);
            setIsSimulationLoading(false);
            const updatedFixtures = updateCLFixtures(knockoutResults, results, knockoutRound);
            setKnockoutResults(updatedFixtures);
            console.log("Results:", updatedFixtures);
        } else {
            setIsSimulationLoading(true);
            setTimeout(async () => {
                const startTime = performance.now();
                const { leagueTable, fixtureResults, goalScorers, assisters, topGoalkeepers, ratings, hatTricks } = await simulateLeague(selectedLeague.id, teams, playerData);
                //console.log('League results:', leagueResults);
                const endTime = performance.now(); // End timing
                const duration = ((endTime - startTime) / 1000).toFixed(2);
                console.log(`Simulation took ${duration} seconds`);;
                setSearchResults([]);
                setLeagueTable(leagueTable);
                setFixtureResults(fixtureResults);
                //console.log('Fixture results', fixtureResults);
                setGoalScorers(goalScorers);
                setAssisters(assisters);
                setTopGoalkeepers(topGoalkeepers);
                setTopPlayers(ratings);
                //console.log('Player ratings after returning:', ratings);
                setCurrentMatchday(0);
                //console.log('League table:', leagueTable);
                setHatTricks(hatTricks);
                setIsSimulationLoading(false);
            }, 100);
        }
    }

    const handleMatchdayChange = (num) => {
        setCurrentMatchday(num);
        setSearchResults([]);
    }

    const [displayFixtures, setDisplayFixtures] = useState([]);

    const handleFixtureClick = (stageKey, index) => {
        // Extract first and second leg results
        const firstLeg = knockoutResults[stageKey]?.firstLegResults[index];
        const secondLeg = knockoutResults[stageKey]?.secondLegResults[index];

        if (firstLeg && secondLeg) {
            // Adjust second leg scores
            const adjustedSecondLeg = {
                ...secondLeg,
                homeGoals: secondLeg.homeGoals - firstLeg.awayGoals,
                awayGoals: secondLeg.awayGoals - firstLeg.homeGoals,
            };

            // Create the array for display
            const fixturesArray = [firstLeg, adjustedSecondLeg];
            console.log("Fixtures array", fixturesArray)
            setDisplayFixtures(fixturesArray);
            // Update the fixtures
            //setFixtures(fixturesArray);
        }
    };


    return (
        <div className="league-simulator">
            <h2>Simulate a League</h2>
            <div className="league-dropdown">
                <label htmlFor="league-select">Choose a league:</label>
                <select
                    id="league-select"
                    onChange={handleLeagueChange}
                    onClick={handleLeagueClick}
                    value={selectedLeague.id}>
                    {leagues.map(league => (
                        <option key={league.id} value={league.id}>
                            {league.name}
                        </option>
                    ))}
                </select>
                <img
                    src={selectedLeague.icon}
                    alt={`${selectedLeague.name} icon`}
                    className="league-icon"
                    style={{ height: '50px', width: '50px' }}
                />
            </div>
            <button
                onClick={() => simulateLeagueMatches()}
                disabled={arePlayersLoading}
            >Simulate {selectedLeague.name}</button>
            {isSimulationLoading && <LoadingPopup displayText="Currently Simulating..."/>}
            {leagueTable.length > 0 && (
                <>
                    {selectedLeague.id === 99 ? (
                        scheduledFixtures && scheduledFixtures.length > 0 &&
                        <ChampionsLeagueSimulator
                            leagueTable={leagueTable}
                            logos={logos}
                            setFunctions={{ setFixtureResults, setGoalScorers, setAssisters, setHatTricks, setTopGoalkeepers, setTopPlayers, setLeagueTable }}
                            scheduledFixtures={scheduledFixtures}
                            formattedTeams={formattedTeams}
                            knockoutResults={knockoutResults}
                            setKnockoutResults={setKnockoutResults}
                        />
                    ) : (
                        <LeagueTable teams={leagueTable} logos={logos} />
                    )}

                    {fixtureResults && fixtureResults.length > 0 && (
                        <>
                            <LeagueStatistics fixtureResults={fixtureResults} getTeamStatistics={getTeamStatistics} leagueTable={leagueTable} />
                            <Tabs activeTab={activeTab} onChangeTab={setActiveTab} hasKnockouts={!!(knockoutResults && Object.keys(knockoutResults).length > 0)} />
                            {activeTab === 'fixtures' && (
                                <>
                                    <MatchdayNavigation
                                        currentMatchday={currentMatchday}
                                        totalMatchdays={fixtureResults.length}
                                        onMatchdayChange={handleMatchdayChange}
                                    />
                                    <MatchdayResults
                                        matchdayResults={fixtureResults[currentMatchday]}
                                        matchday={currentMatchday}
                                        logos={logos}
                                        allFixtures={fixtureResults}
                                    />
                                    <MatchdayNavigation
                                        currentMatchday={currentMatchday}
                                        totalMatchdays={fixtureResults.length}
                                        onMatchdayChange={handleMatchdayChange}
                                    />
                                </>
                            )}
                            {activeTab === 'knockout' && <KnockoutResult knockoutResults={knockoutResults} logos={logos} handleFixtureClick={handleFixtureClick} displayFixtures={displayFixtures} />}
                            {activeTab === 'scorers' && <TopScorers goalScorers={goalScorers} logos={logos} />}
                            {activeTab === 'assisters' && <TopAssisters assisters={assisters} logos={logos} />}
                            {activeTab === 'goalkeepers' && <BestGoalkeepers topGoalkeepers={topGoalkeepers} logos={logos} />}
                            {activeTab === 'topPlayers' && <BestPerformingPlayers topPlayers={topPlayers} logos={logos} />}
                            {activeTab === 'hatTricks' && <HatTricks hatTricks={hatTricks} logos={logos} />}
                        </>
                    )}
                </>
            )}
            {showCurrentKnockout && (
                <>
                    <CLKnockoutBracket
                        logos={logos}
                        knockoutResults={knockoutResults}
                    />
                </>
            )}
        </div >
    );
};

export default LeagueSimulator;